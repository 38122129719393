import firebase from 'firebase';
import { call, select, takeLatest } from 'redux-saga/effects';

import { getCategoryItemRef, getUserRef } from '../../../api/firestore.api';
import { selectUser } from '../../../feature/auth/store/selector';
import { categoriesActions } from '../../../feature/categories/store/reducer';
import { error } from '../../../utils/logger';

function* addCategoryValue(action: ReturnType<typeof categoriesActions.addCategoryValue>) {
  const user = (yield select(selectUser)) as firebase.User | null;
  if (!user) {
    throw new Error('User not found');
  }
  const { path, value, type, label, comment } = action.payload;
  yield call(() =>
    getUserRef(user.uid).collection(path).add({
      label: label,
      createdAt: firebase.firestore.Timestamp.now(),
      sum: value,
      name: type,
      comment,
    })
  );
}

function* deleteCategoryItem(action: ReturnType<typeof categoriesActions.deleteCategoryItem>) {
  const user = (yield select(selectUser)) as firebase.User | null;
  if (!user) {
    throw new Error('User not found');
  }
  const { id: itemId, path } = action.payload;
  try {
    yield call(() => getCategoryItemRef(user.uid, path).doc(itemId).delete());
  } catch (e: unknown) {
    error(e);
  }
}

export function* categoriesWorker() {
  yield takeLatest(categoriesActions.addCategoryValue, addCategoryValue);
  yield takeLatest(categoriesActions.deleteCategoryItem, deleteCategoryItem);
}
