import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';

import { Table } from '../../../../components/atom/Table';
import { CATEGORIES_SETTINGS_ROUTE } from '../../../../constants/routes';
import { history } from '../../../../history';
import { action } from '../../../../store';
import { createNotification } from '../../../../utils/helpers';
import { authActions } from '../../../auth/store/actions';
import { selectSpendCategories } from '../../../settings/store/selector';
import { CategoriesPath } from '../../../settings/store/types';
import { AddValueModal } from '../../components/AddValueModal';
import { HistoryModal } from '../../components/HistoryModal';
import { LastItems } from '../../components/LastItems';
import { SelectPeriodModal } from '../../components/SelectPeriodModal';
import { selectSpend } from '../../store/selectors';

type DataItem = {
  id: number;
  type: string;
  lastSum: number;
  date: string;
  sum: number;
  label: string;
};
export function SpendScreen() {
  const [showAddValueModal, setShowAddValueModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const spend = useSelector(selectSpend);
  const categories = useSelector(selectSpendCategories);
  const allCategories = useMemo(() => categories.map(i => ({ ...i, path: 'spend' as CategoriesPath })), [categories]);
  const [type, setType] = useState(categories[0]?.latinName);

  useEffect(() => {
    if (!categories.length) {
      action(authActions.addNotificationAction(createNotification('warning', 'Сначала создайте категории')));
      history.push(CATEGORIES_SETTINGS_ROUTE);
    }

    setType(categories[0]?.latinName);
  }, [categories, categories.length]);

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Тип',
        accessor: 'label',
      },
      {
        Header: 'Общая сумма',
        accessor: 'sum',
      },
      {
        Header: 'Последняя сумма',
        accessor: 'lastSum',
      },
      {
        Header: 'Дата',
        accessor: 'date',
      },
      {
        Header: 'Действия',
        Cell: (props: CellProps<DataItem>) => {
          return (
            <div className='d-flex align-items-center justify-content-end'>
              <Button
                onClick={() => {
                  setType(props.row.original.type);
                  setShowAddValueModal(true);
                }}
                className='me-2'
                variant='primary'
              >
                Добавить
              </Button>
              <Button
                onClick={() => {
                  setType(props.row.original.type);
                  setShowHistoryModal(true);
                }}
                variant='secondary'
              >
                История
              </Button>
            </div>
          );
        },
        width: 200,
      },
    ],
    []
  );

  const tableData: DataItem[] = useMemo(() => {
    const spendData = spend.reduce<{
      [key: string]: { type: string; lastSum: number; date: string; sum: number; label: string };
    }>((acc, item) => {
      return {
        ...acc,
        [item.name]: {
          ...acc[item.name],
          lastSum: acc[item.name]?.lastSum ? acc[item.name].lastSum : item.sum,
          date: acc[item.name]?.date ? acc[item.name].date : item.createdAt.toDate().toLocaleDateString(),
          sum: acc[item.name]?.sum ? acc[item.name]?.sum + item.sum : item.sum,
          label: item.label,
          type: item.name,
        },
      };
    }, {});
    return Object.values(spendData).map((value, idx) => {
      return { ...value, id: idx + 1 };
    });
  }, [spend]);
  const historyItems = useMemo(() => {
    if (type === '') {
      return spend;
    }
    return spend.filter(i => i.name === type);
  }, [spend, type]);
  const [showDateModal, setShowDateModal] = useState(false);
  return (
    <div>
      <SelectPeriodModal show={showDateModal} onHide={() => setShowDateModal(false)} />
      <LastItems items={spend} />
      <div className='page-block categories'>
        <div className='page-block__header categories__header'>
          <div className='row align-items-center'>
            <h1 className='col mb-0'>Траты</h1>
            <div className='col-auto'>
              <Button
                onClick={() => {
                  setShowDateModal(true);
                }}
                className='categories__heder-btn'
                variant='primary'
              >
                Период
              </Button>
            </div>
            <div className='col-auto'>
              <Button
                onClick={() => {
                  setType('');
                  setShowHistoryModal(true);
                }}
                className='categories__heder-btn'
                variant='secondary'
              >
                История
              </Button>
            </div>
          </div>
        </div>
        <div className='page-block__body categories__table'>
          <Table columns={columns} data={tableData} />
        </div>
        <div className='page-block__bottom categories__bottom'>
          <Button variant='primary' onClick={() => setShowAddValueModal(true)}>
            Добавить
          </Button>
        </div>
      </div>
      <AddValueModal
        show={showAddValueModal}
        onHide={() => {
          setShowAddValueModal(false);
          setType(categories[0]?.latinName);
        }}
        categories={allCategories}
        initialType={type}
      />
      <HistoryModal
        historyData={historyItems}
        show={showHistoryModal}
        onHide={() => {
          setShowHistoryModal(false);
          setTimeout(() => {
            setType(allCategories[0]?.latinName);
          }, 150);
        }}
      />
    </div>
  );
}
