import { call } from 'redux-saga/effects';

import { goToAction } from '..';
import {
  NOT_FOUND_ROUTE,
  CATEGORIES_ROUTE,
  REGISTER_ROUTE,
  RECOVERY_PASSWORD_ROUTE,
  LOGIN_ROUTE,
} from '../../constants/routes';
import { history } from '../../history';
import { Saga } from '../../types';

export type RouteHandler = { route: string; saga: Saga };

export function* toLogin() {
  yield call(history.push, LOGIN_ROUTE);
}

export function* toWelcome() {
  yield call(history.push, CATEGORIES_ROUTE);
}

export function* to404() {
  yield call(history.push, NOT_FOUND_ROUTE);
}

export function* toRegister() {
  yield call(history.push, REGISTER_ROUTE);
}

export function* toRecoveryPassword() {
  yield call(history.push, RECOVERY_PASSWORD_ROUTE);
}

export function* goTo(param: ReturnType<typeof goToAction>) {
  yield call(history.push, param.payload);
}
