import React, { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { endDateDate, END_RANGE_DATA, START_RANGE_DATA } from '../../../../constants';

export const SelectPeriodModal: FC<{ show: boolean; onHide: () => void }> = ({ show, onHide }) => {
  const [selectionRange, setSelectionRange] = useState<{ startDate: Date; endDate: Date; key: string }>({
    startDate: START_RANGE_DATA,
    endDate: endDateDate || new Date(Date.now()),
    key: 'selection',
  });
  return (
    <Modal show={show} onHide={onHide} size='lg' aria-labelledby='add-category-item' centered>
      <Modal.Header closeButton>
        <Modal.Title id='add-category-item'>История</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={date => {
            const selection = (date as { selection: { startDate: Date; endDate: Date; key: string } }).selection;
            setSelectionRange(selection);
          }}
        />
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <Button onClick={onHide} className='ms-auto' variant='danger' type='button'>
              Закрыть
            </Button>
            <Button
              onClick={() => {
                const { startDate, endDate } = selectionRange;
                localStorage.setItem('startDate', JSON.stringify(startDate));
                localStorage.setItem('endDate', JSON.stringify(endDate));
                onHide();
                location.reload();
              }}
              className='ms-2'
              variant='primary'
              type='button'
            >
              Сохранить
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
