import { takeLatest } from 'redux-saga/effects';

import { authActions } from '../../../feature/auth/store/actions';
import { emailLoginSaga, googleLoginSaga } from '../../../feature/auth/store/saga';
import { toRecoveryPassword, toRegister } from '../utils';

export function* loginWorker() {
  yield takeLatest(authActions.googleLoginAction, googleLoginSaga);
  yield takeLatest(authActions.emailLoginAction, emailLoginSaga);
  yield takeLatest(authActions.toRegisterClick, toRegister);
  yield takeLatest(authActions.toRecoveryPasswordClick, toRecoveryPassword);
}
