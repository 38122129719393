import React, { FC } from 'react';
import { Badge, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { action } from '../../../../store';
import { badgeClassesDictionary } from '../../screen/AllCategories';
import { categoriesActions } from '../../store/reducer';
import { CategoriesItem } from '../../types';

export const HistoryModal: FC<{ historyData: CategoriesItem[]; show: boolean; onHide: () => void }> = ({
  historyData,
  show,
  onHide,
}) => {
  return (
    <Modal show={show} onHide={onHide} size='lg' aria-labelledby='add-category-item' centered>
      <Modal.Header closeButton>
        <Modal.Title id='add-category-item'>История</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className='mb-3 history'>
          <li className='border-bottom d-none d-md-block'>
            <div className='row'>
              <p className='col-1 mb-0 p-3'>&#x2116;</p>
              <p className='col-3 mb-0 p-3'>Тип</p>
              <p className='col-3 mb-0 p-3'>Сума</p>
              <p className='col-4 mb-0 p-3'>Дата</p>
              <p className='col-1 mb-0 p-3'></p>
            </div>
          </li>
          {historyData.map((i, idx) => {
            return (
              <li key={i.createdAt.toMillis()} className='border-bottom'>
                <div className='row'>
                  <p className='col-sm-1  d-none d-md-flex align-items-center mb-0 p-1 p-md-3'>{idx + 1}</p>
                  <p className='col col-sm-3 mb-0 p-1 p-md-3 position-relative d-flex align-items-center'>
                    {i.label}
                    {i.comment && (
                      <OverlayTrigger key={i.id} placement={'auto'} overlay={<Tooltip id={i.id}>{i.comment}</Tooltip>}>
                        <Badge className='position-absolute top-50 translate-middle end-0' bg='dark'>
                          i
                        </Badge>
                      </OverlayTrigger>
                    )}
                  </p>
                  <p className='col col-sm-3 mb-0 p-1 p-md-3 d-flex align-items-center'>
                    <span className={`badge badge-${badgeClassesDictionary[i.categoryPath]}`}>{i.sum}</span>
                  </p>
                  <time className='col col-sm-4 p-1 p-md-3 d-flex align-items-center'>
                    {i.createdAt.toDate().toLocaleString()}
                  </time>
                  <div className='col col-sm-1 p-1 p-md-3 d-flex align-items-center'>
                    <Button
                      onClick={() => action(categoriesActions.deleteCategoryItem({ id: i.id, path: i.categoryPath }))}
                      variant='danger'
                    >
                      X
                    </Button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <Button onClick={onHide} className='ms-auto' variant='danger' type='button'>
              Закрыть
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
