import React, { FC } from 'react';

export const IncomingIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.5 1.4375C5.94316 1.4375 1.4375 5.94316 1.4375 11.5C1.4375 17.0568 5.94316 21.5625 11.5 21.5625C17.0568 21.5625 21.5625 17.0568 21.5625 11.5C21.5625 5.94316 17.0568 1.4375 11.5 1.4375ZM11.5 19.8555C6.88652 19.8555 3.14453 16.1135 3.14453 11.5C3.14453 6.88652 6.88652 3.14453 11.5 3.14453C16.1135 3.14453 19.8555 6.88652 19.8555 11.5C19.8555 16.1135 16.1135 19.8555 11.5 19.8555ZM12.5714 10.9789L12.0009 10.8464V7.82988C12.8544 7.94668 13.3822 8.48125 13.4721 9.13711C13.4833 9.22695 13.5597 9.29209 13.6495 9.29209H14.658C14.7636 9.29209 14.8467 9.2 14.8377 9.09443C14.7007 7.69512 13.5484 6.79668 12.0099 6.6417V5.90723C12.0099 5.8084 11.929 5.72754 11.8302 5.72754H11.199C11.1002 5.72754 11.0193 5.8084 11.0193 5.90723V6.64844C9.4291 6.80342 8.18477 7.68164 8.18477 9.32129C8.18477 10.8396 9.30332 11.5719 10.478 11.8526L11.0328 11.9941V15.1993C10.04 15.0668 9.48301 14.5367 9.36846 13.8225C9.35498 13.7371 9.27861 13.6742 9.19102 13.6742H8.15332C8.04775 13.6742 7.96465 13.7641 7.97363 13.8696C8.07471 15.105 9.01133 16.2415 11.0104 16.3875V17.0928C11.0104 17.1916 11.0912 17.2725 11.19 17.2725H11.8279C11.9268 17.2725 12.0076 17.1916 12.0076 17.0905L12.0031 16.3785C13.7618 16.2235 15.0196 15.2824 15.0196 13.5934C15.0174 12.0346 14.0269 11.3383 12.5714 10.9789ZM11.0306 10.615C10.9048 10.5791 10.7992 10.5454 10.6937 10.5027C9.93447 10.2287 9.58184 9.78623 9.58184 9.21572C9.58184 8.40039 10.1995 7.93545 11.0306 7.82988V10.615ZM12.0009 15.2061V12.203C12.0705 12.2232 12.1334 12.239 12.1985 12.2524C13.2609 12.5759 13.6181 13.0251 13.6181 13.7146C13.6181 14.5929 12.9577 15.1207 12.0009 15.2061Z'
        fill={color}
      />
    </svg>
  );
};
