import firebase from 'firebase/app';

import { CategoriesItem } from '../feature/categories/types';

export const categoryItemConverter = {
  toFirestore(data: Partial<CategoriesItem>): firebase.firestore.DocumentData {
    return data;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): CategoriesItem {
    const id = snapshot.id;
    const data = snapshot.data(options) as CategoriesItem;

    return {
      ...data,
      id,
    };
  },
};
