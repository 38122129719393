import { info } from '../utils/logger';

export const CURRENT_DATA = new Date(Date.now());
const startDate = localStorage.getItem('startDate');
const endDate = localStorage.getItem('endDate');
export const endDateDate = endDate !== null ? new Date(JSON.parse(endDate)) : endDate;
export const START_RANGE_DATA = startDate
  ? new Date(JSON.parse(startDate))
  : new Date(CURRENT_DATA.getFullYear(), CURRENT_DATA.getMonth(), 1);

export const END_RANGE_DATA = endDateDate
  ? new Date(endDateDate.getFullYear(), endDateDate.getMonth(), endDateDate.getDate() + 1)
  : new Date(CURRENT_DATA.getFullYear(), CURRENT_DATA.getMonth(), CURRENT_DATA.getDate() + 1);
info(END_RANGE_DATA);
