import React, { FC } from 'react';

export const TelegramIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23 11.5C23 17.8509 17.8509 23 11.5 23C5.14913 23 0 17.8509 0 11.5C0 5.14913 5.14913 0 11.5 0C17.8509 0 23 5.14913 23 11.5ZM11.9121 8.48987C10.7937 8.95467 8.55792 9.91779 5.20567 11.3783C4.66133 11.5949 4.37575 11.8067 4.34987 12.0137C4.30579 12.3644 4.74471 12.5024 5.34079 12.6893C5.42225 12.7152 5.50658 12.741 5.59283 12.7698C6.18029 12.9605 6.96996 13.1838 7.38013 13.1924C7.75292 13.2001 8.16883 13.0468 8.62788 12.7324C11.7597 10.6174 13.3764 9.54883 13.478 9.52583C13.5499 9.50954 13.6495 9.48846 13.7166 9.54883C13.7837 9.60825 13.777 9.72133 13.7703 9.752C13.7262 9.93696 12.007 11.5364 11.1157 12.3644C10.8378 12.6222 10.6413 12.8053 10.6011 12.8474C10.511 12.9404 10.419 13.0295 10.3308 13.1148C9.78458 13.64 9.37633 14.0348 10.3538 14.6788C10.8234 14.9883 11.1991 15.2442 11.5738 15.4991C11.983 15.778 12.3912 16.0559 12.9202 16.4028C13.0544 16.491 13.1828 16.582 13.3084 16.6712C13.7847 17.0114 14.213 17.3161 14.742 17.2682C15.0487 17.2395 15.3669 16.951 15.5279 16.0895C15.9083 14.052 16.6577 9.63988 16.8312 7.82096C16.8418 7.66994 16.8353 7.51821 16.812 7.36863C16.7981 7.24781 16.7393 7.13664 16.6472 7.05717C16.5102 6.94504 16.2974 6.92108 16.2016 6.923C15.7694 6.93067 15.1062 7.16162 11.9121 8.48987Z'
        fill={color}
      />
    </svg>
  );
};
