import { createAction } from '@reduxjs/toolkit';

import { actions } from './reducer';
import { emailLoginPayload, emailRegisterPayload, NotificationItem } from './types';

export const authActions = {
  ...actions,
  googleLoginAction: createAction('auth/googleLogin'),
  emailRegisterAction: createAction<emailRegisterPayload>('auth/emailRegister'),
  emailLoginAction: createAction<emailLoginPayload>('auth/emailLogin'),
  recoveryPasswordAction: createAction<{ email: string }>('auth/recoveryPassword'),
  logoutAction: createAction('auth/logout'),
  toRegisterClick: createAction('auth/toRegister'),
  toRecoveryPasswordClick: createAction('auth/toRecoveryPassword'),
  toLoginClick: createAction('auth/toLogin'),
  closeNotificationClick: createAction<void>('EVENT/closeNotificationClick'),
  removeNotificationAction: createAction<number>('EVENT/removeNotificationAction'),
  addNotificationAction: createAction<NotificationItem>('EVENT/addNotificationAction'),
};
