import { Location } from 'history';
import { call, takeEvery, CancelledEffect, cancelled, fork, put } from 'redux-saga/effects';

import { setUserData } from '../feature/auth/authUtils';
import { authActions } from '../feature/auth/store/actions';
import { categoriesActions } from '../feature/categories/store/reducer';
import { getCategories } from '../feature/settings/store/reducer';
import { settingsSaga } from '../feature/settings/store/saga';

import { goToAction } from '.';
import { signOut } from './auth/authUtils';
import {
  setInitialData,
  watchAccumulationWorker,
  watchIncomesWorker,
  watchSpendWorker,
} from './categories/categoriesUtils';
import { onHistory, onNavigate } from './routes';
import { privateRouteHandlers } from './routes/routeHandlers';
import { goTo, to404 } from './routes/utils';

export function* handleSignedIn() {
  yield takeEvery(authActions.logoutAction, signOut);
  yield takeEvery(goToAction, goTo);

  yield call(setUserData);
  yield call(setInitialData);

  yield fork(watchIncomesWorker);
  yield fork(watchAccumulationWorker);
  yield fork(watchSpendWorker);
  yield fork(settingsSaga);
  yield put(getCategories());
  yield put(categoriesActions.setIsAppLoading(false));
  try {
    yield call(
      onHistory,
      function* (location: Location<string>) {
        yield call(onNavigate, location, privateRouteHandlers, to404);
      },
      'Private'
    );
  } finally {
    const isCancel = (yield cancelled()) as CancelledEffect;
    if (isCancel) {
      return;
    }
  }
}
