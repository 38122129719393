import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { action } from '../../../../store';
import { createNotification } from '../../../../utils/helpers';
import { authActions } from '../../../auth/store/actions';
import { CategoriesPath, settingItem } from '../../../settings/store/types';
import { categoriesActions } from '../../store/reducer';

export const AddValueModal: FC<{
  initialType: string;

  categories: (settingItem & { path: CategoriesPath })[];
  show: boolean;
  onHide: () => void;
}> = ({ initialType, categories, show, onHide }) => {
  const [sum, setSum] = useState('');
  const [comment, setComment] = useState('');
  const [type, setType] = useState(initialType);
  const [categoryPath, setCategoryPath] = useState<CategoriesPath>('spend');

  useEffect(() => {
    setCategoryPath(categories.find(i => i.latinName === type)?.path || 'spend');
    setType(initialType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, initialType]);

  return (
    <Modal onHide={onHide} show={show} size='lg' aria-labelledby='add-category-item' centered>
      <Modal.Header closeButton>
        <Modal.Title id='add-category-item'>Добавить</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={e => {
            e.preventDefault();
            if (isNaN(Number(sum))) {
              return action(
                authActions.addNotificationAction(createNotification('danger', 'Сумма должна быть числом'))
              );
            }
            if (sum.trim().length === 0) {
              action(authActions.addNotificationAction(createNotification('danger', 'Введите сумму')));
              return;
            }
            if (Number(sum) === 0) {
              action(
                authActions.addNotificationAction(createNotification('danger', 'Сумма должна быть отличной от 0'))
              );
              return;
            }
            if (type === '') {
              action(authActions.addNotificationAction(createNotification('danger', 'Тип не выбран')));
              return;
            }

            action(
              categoriesActions.addCategoryValue({
                type: type,
                value: Number(sum),
                path: categoryPath,
                label: categories.find(i => i.latinName === type)?.label || '',
                comment,
              })
            );
            onHide();
            setSum('');
            setComment('');
          }}
        >
          <div className='row'>
            <Form.Group className='mb-3 col-4'>
              <Form.Control
                value={sum}
                onInput={e => setSum(e.currentTarget.value)}
                type='number'
                placeholder='Cумма'
              />
            </Form.Group>

            <Form.Group className='mb-3 col-8'>
              <Form.Select
                value={type}
                onChange={e => {
                  setCategoryPath(categories.find(i => i.latinName === e.currentTarget.value)?.path || 'spend');
                  setType(e.currentTarget.value);
                }}
                name='type'
              >
                {categories.map(item => {
                  return (
                    <option key={item.latinName} value={item.latinName}>
                      {item.label}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className='mb-3 col-12'>
              <Form.Control
                value={comment}
                onChange={e => setComment(e.currentTarget.value)}
                type='text'
                placeholder='Введите комментарий'
              />
            </Form.Group>
          </div>
          <div className='row justify-content-end'>
            <div className='col-auto'>
              <Button onClick={onHide} className='ms-auto' variant='danger' type='button'>
                Отменить
              </Button>
            </div>
            <div className='col-auto'>
              <Button className='ms-auto' variant='primary' type='submit'>
                Добавить
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
