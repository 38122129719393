import { takeLatest } from 'redux-saga/effects';

import { authActions } from '../../../feature/auth/store/actions';
import { emailRegisterSaga } from '../../../feature/auth/store/saga';
import { toLogin } from '../utils';

export function* registerWorker() {
  yield takeLatest(authActions.emailRegisterAction, emailRegisterSaga);
  yield takeLatest(authActions.toLoginClick, toLogin);
}
