import React, { FC } from 'react';

export const AllTimeIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2_100)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.5278 21.7222C19.375 21.7222 20.1876 21.3857 20.7866 20.7866C21.3857 20.1875 21.7223 19.375 21.7223 18.5278C21.7223 17.6806 21.3857 16.868 20.7866 16.269C20.1876 15.6699 19.375 15.3333 18.5278 15.3333C17.6806 15.3333 16.8681 15.6699 16.269 16.269C15.6699 16.868 15.3334 17.6806 15.3334 18.5278C15.3334 19.375 15.6699 20.1875 16.269 20.7866C16.8681 21.3857 17.6806 21.7222 18.5278 21.7222ZM18.5278 23C19.7139 23 20.8515 22.5288 21.6902 21.6901C22.5289 20.8514 23 19.7139 23 18.5278C23 17.3417 22.5289 16.2041 21.6902 15.3654C20.8515 14.5267 19.7139 14.0556 18.5278 14.0556C17.3417 14.0556 16.2042 14.5267 15.3655 15.3654C14.5268 16.2041 14.0556 17.3417 14.0556 18.5278C14.0556 19.7139 14.5268 20.8514 15.3655 21.6901C16.2042 22.5288 17.3417 23 18.5278 23Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.5278 16.2917C18.6972 16.2917 18.8598 16.359 18.9796 16.4788C19.0994 16.5986 19.1667 16.7611 19.1667 16.9306V18.2633L19.6184 18.715C19.7348 18.8355 19.7992 18.9969 19.7977 19.1644C19.7963 19.3319 19.7291 19.4921 19.6106 19.6106C19.4922 19.729 19.3319 19.7962 19.1644 19.7977C18.9969 19.7991 18.8355 19.7347 18.715 19.6184L17.8889 18.7923V16.9306C17.8889 16.7611 17.9562 16.5986 18.076 16.4788C18.1959 16.359 18.3584 16.2917 18.5278 16.2917Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.11116 10.8611H3.83338V12.1389H5.11116V10.8611ZM3.83338 9.58333C3.49449 9.58333 3.16949 9.71795 2.92986 9.95758C2.69023 10.1972 2.5556 10.5222 2.5556 10.8611V12.1389C2.5556 12.4778 2.69023 12.8028 2.92986 13.0424C3.16949 13.282 3.49449 13.4167 3.83338 13.4167H5.11116C5.45005 13.4167 5.77505 13.282 6.01468 13.0424C6.25431 12.8028 6.38894 12.4778 6.38894 12.1389V10.8611C6.38894 10.5222 6.25431 10.1972 6.01468 9.95758C5.77505 9.71795 5.45005 9.58333 5.11116 9.58333H3.83338Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.2222 10.8611H8.94446V12.1389H10.2222V10.8611ZM8.94446 9.58333C8.60558 9.58333 8.28057 9.71795 8.04094 9.95758C7.80131 10.1972 7.66669 10.5222 7.66669 10.8611V12.1389C7.66669 12.4778 7.80131 12.8028 8.04094 13.0424C8.28057 13.282 8.60558 13.4167 8.94446 13.4167H10.2222C10.5611 13.4167 10.8861 13.282 11.1258 13.0424C11.3654 12.8028 11.5 12.4778 11.5 12.1389V10.8611C11.5 10.5222 11.3654 10.1972 11.1258 9.95758C10.8861 9.71795 10.5611 9.58333 10.2222 9.58333H8.94446Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.3333 10.8611H14.0555V12.1389H15.3333V10.8611ZM14.0555 9.58333C13.7167 9.58333 13.3917 9.71795 13.152 9.95758C12.9124 10.1972 12.7778 10.5222 12.7778 10.8611V12.1389C12.7778 12.4778 12.9124 12.8028 13.152 13.0424C13.3917 13.282 13.7167 13.4167 14.0555 13.4167H15.3333C15.6722 13.4167 15.9972 13.282 16.2369 13.0424C16.4765 12.8028 16.6111 12.4778 16.6111 12.1389V10.8611C16.6111 10.5222 16.4765 10.1972 16.2369 9.95758C15.9972 9.71795 15.6722 9.58333 15.3333 9.58333H14.0555Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.11116 15.9722H3.83338V17.25H5.11116V15.9722ZM3.83338 14.6944C3.49449 14.6944 3.16949 14.8291 2.92986 15.0687C2.69023 15.3083 2.5556 15.6333 2.5556 15.9722V17.25C2.5556 17.5889 2.69023 17.9139 2.92986 18.1535C3.16949 18.3932 3.49449 18.5278 3.83338 18.5278H5.11116C5.45005 18.5278 5.77505 18.3932 6.01468 18.1535C6.25431 17.9139 6.38894 17.5889 6.38894 17.25V15.9722C6.38894 15.6333 6.25431 15.3083 6.01468 15.0687C5.77505 14.8291 5.45005 14.6944 5.11116 14.6944H3.83338Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.2222 15.9722H8.94446V17.25H10.2222V15.9722ZM8.94446 14.6944C8.60558 14.6944 8.28057 14.8291 8.04094 15.0687C7.80131 15.3083 7.66669 15.6333 7.66669 15.9722V17.25C7.66669 17.5889 7.80131 17.9139 8.04094 18.1535C8.28057 18.3932 8.60558 18.5278 8.94446 18.5278H10.2222C10.5611 18.5278 10.8861 18.3932 11.1258 18.1535C11.3654 17.9139 11.5 17.5889 11.5 17.25V15.9722C11.5 15.6333 11.3654 15.3083 11.1258 15.0687C10.8861 14.8291 10.5611 14.6944 10.2222 14.6944H8.94446Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.27778 3.83334C1.27778 3.66389 1.34509 3.50139 1.4649 3.38158C1.58472 3.26176 1.74722 3.19445 1.91667 3.19445H5.11111V1.91667H1.91667C1.40834 1.91667 0.920823 2.11861 0.561379 2.47805C0.201934 2.83749 0 3.32501 0 3.83334V19.1667C0 19.675 0.201934 20.1625 0.561379 20.522C0.920823 20.8814 1.40834 21.0833 1.91667 21.0833H14.8574C14.5849 20.6931 14.3768 20.2617 14.2408 19.8056H1.91667C1.74722 19.8056 1.58472 19.7383 1.4649 19.6184C1.34509 19.4986 1.27778 19.3361 1.27778 19.1667V3.83334ZM17.8889 14.1003C18.3127 14.0397 18.7429 14.0397 19.1667 14.1003V3.83334C19.1667 3.32501 18.9647 2.83749 18.6053 2.47805C18.2458 2.11861 17.7583 1.91667 17.25 1.91667H15.3333V3.19445H17.25C17.4194 3.19445 17.5819 3.26176 17.7018 3.38158C17.8216 3.50139 17.8889 3.66389 17.8889 3.83334V14.1003ZM6.38889 3.19445H13.1375V1.91667H6.38889V3.19445Z'
          fill={color}
        />
        <path fillRule='evenodd' clipRule='evenodd' d='M19.1667 7.66668H1.27777V6.3889H19.1667V7.66668Z' fill={color} />
        <path
          d='M3.83331 0.638889C3.83331 0.469445 3.90062 0.306941 4.02044 0.187126C4.14025 0.0673114 4.30276 0 4.4722 0C4.64165 0 4.80415 0.0673114 4.92396 0.187126C5.04378 0.306941 5.11109 0.469445 5.11109 0.638889V3.83333C5.11109 4.00278 5.04378 4.16528 4.92396 4.2851C4.80415 4.40491 4.64165 4.47222 4.4722 4.47222C4.30276 4.47222 4.14025 4.40491 4.02044 4.2851C3.90062 4.16528 3.83331 4.00278 3.83331 3.83333V0.638889Z'
          fill={color}
        />
        <path
          d='M12.7778 0.638889C12.7778 0.469445 12.8451 0.306941 12.9649 0.187126C13.0847 0.0673114 13.2472 0 13.4167 0C13.5861 0 13.7486 0.0673114 13.8684 0.187126C13.9882 0.306941 14.0555 0.469445 14.0555 0.638889V3.83333C14.0555 4.00278 13.9882 4.16528 13.8684 4.2851C13.7486 4.40491 13.5861 4.47222 13.4167 4.47222C13.2472 4.47222 13.0847 4.40491 12.9649 4.2851C12.8451 4.16528 12.7778 4.00278 12.7778 3.83333V0.638889Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2_100'>
          <rect width='23' height='23' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
