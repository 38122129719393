import { matchPath } from 'react-router';

import { NotificationItem, NotificationType } from '../feature/auth/store/types';

export const pathMatchesRoute = (pathname: string, route: string) => !!matchPath(pathname, route);

let notificationId = 0;

const getNotificationId = () => {
  notificationId += 1;
  return notificationId;
};

export const createNotification = (type: NotificationType, message: string): NotificationItem => {
  return {
    id: getNotificationId(),
    message,
    type,
  };
};
