import {
  INDEX_ROUTE,
  LOGIN_ROUTE,
  RECOVERY_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  CATEGORIES_ROUTE,
  PRIVATE_ROUTES,
  INCOMING_ROUTE,
  ACCUMULATION_ROUTE,
  ALL_ROUTE,
} from '../../constants/routes';

import { categoriesWorker } from './screenWorkers/categoriesWorker';
import { loginWorker } from './screenWorkers/loginWorker';
import { recoveryPasswordWorker } from './screenWorkers/recoveryPasswordWorker';
import { registerWorker } from './screenWorkers/registerWorker';
import { RouteHandler, toLogin, toWelcome } from './utils';

export const publicRouteHandlers: RouteHandler[] = [
  { route: LOGIN_ROUTE, saga: loginWorker },
  { route: REGISTER_ROUTE, saga: registerWorker },
  { route: RECOVERY_PASSWORD_ROUTE, saga: recoveryPasswordWorker },
  { route: INDEX_ROUTE, saga: toLogin },
];
export const privateRouteHandlers: RouteHandler[] = [
  { route: REGISTER_ROUTE, saga: toWelcome },
  { route: RECOVERY_PASSWORD_ROUTE, saga: toWelcome },
  { route: CATEGORIES_ROUTE, saga: categoriesWorker },
  { route: INCOMING_ROUTE, saga: categoriesWorker },
  { route: ACCUMULATION_ROUTE, saga: categoriesWorker },
  { route: ALL_ROUTE, saga: categoriesWorker },
  { route: LOGIN_ROUTE, saga: toWelcome },
  ...PRIVATE_ROUTES.map(item => ({ route: item, saga: categoriesWorker })),
  { route: INDEX_ROUTE, saga: toWelcome },
];
