import React, { FC, useEffect, useState } from 'react';

import { Header } from '../../atom/Header';
import { Sidebar } from '../../atom/Sidebar';

export const Layout: FC = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    document.body.style.overflow = openMenu ? 'hidden' : 'auto';
  }, [openMenu]);
  return (
    <>
      <div className={`page__sidebar ${openMenu ? 'active' : ''}`}>
        <button onClick={() => setOpenMenu(!openMenu)} className={`sidebar__burger ${openMenu ? 'active' : ''}`}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Sidebar onMenuClose={() => setOpenMenu(false)} />
      </div>
      <div className='page__body'>
        <Header />
        <div className='page__body-main'>{children}</div>
      </div>
    </>
  );
};
