import React, { FC, useState } from 'react';

import { sidebarLinks } from '../../../constants/sidebarLinks';
import { CloseArrow } from '../../../icons/CloseArrow';

import SidebarItem from './components/SidebarItem';

export const Sidebar: FC<{ onMenuClose: () => void }> = ({ onMenuClose }) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);
  return (
    <div className={`sidebar  ${isClosed ? 'sidebar--closed' : ''}`}>
      <div className='sidebar__header'>
        <a href='/' className='sidebar__link'>
          Zhe-Money
        </a>
        <button onClick={() => setIsClosed(!isClosed)} className='sidebar__close' aria-label='Close sidebar'>
          <CloseArrow />
        </button>
      </div>
      <nav className='sidebar__body'>
        <ul className='sidebar__menu mb-5 mb-sm-0'>
          {sidebarLinks.map(({ name, children }) => {
            return (
              <li key={name} className='sidebar__submenu'>
                {name && <h2 className='sidebar__menu-label'>{name}</h2>}
                <ul className='sidebar__submenu-list'>
                  {children.map(item => (
                    <SidebarItem onMenuClose={onMenuClose} key={item.title} item={item} />
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};
