import React, { FC } from 'react';

export const CloseArrow: FC<{ isActive?: boolean }> = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path className='arrow' d='M8 2L3 7L8 12L7 14L0 7L7 0L8 2Z' fill='#494B74' />
      <path className='arrow' d='M14 2L9 7L14 12L13 14L6 7L13 0L14 2Z' fill='#494B74' />
    </svg>
  );
};
