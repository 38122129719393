import { FormEvent, useCallback, useState } from 'react';

import { Link } from '../../../../components/atom/Link';
import { REGISTER_ROUTE } from '../../../../constants/routes';
import { googleIcon } from '../../../../icons';
import { action } from '../../../../store';
import { warn } from '../../../../utils/logger';
import { authActions } from '../../store/actions';

export const LoginPage = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onEmailInput = useCallback((e: FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value), []);
  const onPasswordInput = useCallback((e: FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value), []);

  const onGoogleLoginClick = useCallback(() => {
    action(authActions.googleLoginAction());
  }, []);

  const onLoginSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!(email.split('@').length === 2)) {
        warn('invalid email');
        return;
      }
      if (password.length < 8) {
        warn('invalid password');
        return;
      }

      action(authActions.emailLoginAction({ email, password }));
    },
    [email, password]
  );
  return (
    <div className='auth d-flex mh-100 justify-content-center align-items-center'>
      <form onSubmit={onLoginSubmit} className='auth__inner d-flex flex-column align-items-center'>
        <h1>Приветствую!</h1>
        <p>
          Войдите в аккаунт или{' '}
          <Link
            href={REGISTER_ROUTE}
            onClick={() => {
              action(authActions.toRegisterClick());
            }}
          >
            создайте новый
          </Link>
        </p>
        <button type={'button'} onClick={onGoogleLoginClick} className='btn btn-outline-dark auth__btn'>
          <img width={24} height={24} src={googleIcon} className='me-1' alt='google' />
          <span>Sign In with Google</span>
        </button>
        <div className='auth__lines'>
          <div className='auth__line auth__line--right' />
          <p className='auth__or'>или</p>
          <div className='auth__line auth__line--left' />
        </div>
        <div className='auth__inputs'>
          <label>
            <input
              onInput={onEmailInput}
              type='email'
              className='form-control auth__input'
              placeholder='Введите ваш E-mail'
            />
          </label>

          <label>
            <input
              onInput={onPasswordInput}
              type='password'
              className='form-control auth__input'
              placeholder='Введите пароль'
            />
          </label>

          <button type={'submit'} className='btn btn-primary auth__btn'>
            Войти в аккаунт
          </button>
        </div>
        <Link
          className='mt-3'
          href='#'
          onClick={() => {
            action(authActions.toRecoveryPasswordClick());
          }}
        >
          Восстановить пароль
        </Link>
      </form>
    </div>
  );
};
