import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CategoriesPath } from '../../settings/store/types';
import { CategoriesItem } from '../types';

import { categoriesInitialState } from './types';

const initialState: categoriesInitialState = {
  spend: [],
  accumulation: [],
  incomes: [],
  isAppLoading: true,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setSpend(state, { payload }: PayloadAction<CategoriesItem[]>) {
      state.spend = payload;
    },
    setIncomes(state, { payload }: PayloadAction<CategoriesItem[]>) {
      state.incomes = payload;
    },
    setAccumulation(state, { payload }: PayloadAction<CategoriesItem[]>) {
      state.accumulation = payload;
    },
    setIsAppLoading(state, { payload }: PayloadAction<boolean>) {
      state.isAppLoading = payload;
    },
  },
});

export const categoriesActions = {
  ...categoriesSlice.actions,
  addCategoryValue:
    createAction<{ type: string; value: number; path: CategoriesPath; label: string; comment?: string }>(
      'categories/addValue'
    ),
  deleteCategoryItem: createAction<{ id: string; path: CategoriesPath }>('categories/deleteItem'),
};

export default categoriesSlice.reducer;
