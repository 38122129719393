import React, { FC, useState } from 'react';
import { useLocation } from 'react-router';

import { SidebarItemType } from '../../../../constants/sidebarLinks';
import { goToAction } from '../../../../sagas';
import { action } from '../../../../store';
import { Link } from '../../Link';

const SidebarItem: FC<{ item: SidebarItemType; onMenuClose: () => void }> = ({ item, onMenuClose }) => {
  const { Icon, title, isButton } = item;
  const location = useLocation();
  const [isActive, setIsActive] = useState<boolean>(false);

  const mouseLeave = () => {
    setIsActive(false);
  };

  const mouseEnter = () => {
    setIsActive(true);
  };
  return (
    <li onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}>
      {isButton ? (
        <div className={`sidebar__item ${isActive ? 'sidebar__item--active' : ''}`}>
          <button onClick={item.onClick}>
            <Icon isActive={isActive} />
            <span className='sidebar__item-name'>{title}</span>
          </button>
        </div>
      ) : (
        <div className={`sidebar__item ${isActive || location.pathname === item.path ? 'sidebar__item--active' : ''}`}>
          <Link
            href={item.path}
            onClick={
              item.external
                ? undefined
                : () => {
                    action(goToAction(item.path));
                    onMenuClose();
                  }
            }
          >
            <Icon isActive={isActive || location.pathname === item.path} />
            <h3 className='sidebar__item-name'>{title}</h3>
          </Link>
        </div>
      )}
    </li>
  );
};

export default SidebarItem;
