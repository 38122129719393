import { FormEvent, useCallback, useState } from 'react';

import { Link } from '../../../../components/atom/Link';
import { action } from '../../../../store';
import { warn } from '../../../../utils/logger';
import { authActions } from '../../store/actions';

export const SignUpPage = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onNameInput = useCallback((e: FormEvent<HTMLInputElement>) => setName(e.currentTarget.value), []);
  const onEmailInput = useCallback((e: FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value), []);
  const onPasswordInput = useCallback((e: FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value), []);

  const onLoginSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!(email.split('@').length === 2)) {
        warn('invalid email');
        return;
      }
      if (password.length < 8) {
        warn('invalid password');
        return;
      }
      if (name.length < 2) {
        warn('name is required');
        return;
      }

      action(authActions.emailRegisterAction({ name, email, password }));
    },
    [email, name, password]
  );
  return (
    <div className='auth d-flex mh-100 justify-content-center align-items-center'>
      <form onSubmit={onLoginSubmit} className='auth__inner d-flex flex-column align-items-center'>
        <h1>Приветствую!</h1>
        <p>
          <Link href='/' onClick={() => action(authActions.toLoginClick())}>
            Войдите в аккаунт
          </Link>{' '}
          или создайте новый
        </p>
        <div className='auth__inputs'>
          <label>
            <input onInput={onNameInput} type='text' className='form-control auth__input' placeholder='Введите имя' />
          </label>
          <label>
            <input
              onInput={onEmailInput}
              type='email'
              className='form-control auth__input'
              placeholder='Введите ваш E-mail'
            />
          </label>

          <label>
            <input
              onInput={onPasswordInput}
              type='password'
              className='form-control auth__input'
              placeholder='Введите пароль'
            />
          </label>

          <button type={'submit'} className='btn btn-primary auth__btn'>
            Создать аккаунт
          </button>
        </div>
      </form>
    </div>
  );
};
