import { createAction } from '@reduxjs/toolkit';
import { all, call } from 'redux-saga/effects';

import { onAuth } from './auth/authUtils';
import { notificationSaga } from './notification/notificationWorker';
import { handleSignedIn } from './privateLogic';
import { handleSignedOut } from './publicLogic';

export default function* rootSaga() {
  yield all([call(onAuth, handleSignedIn, handleSignedOut), call(notificationSaga)]);
}

export const goToAction = createAction<string>('common/goTo');
