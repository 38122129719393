import { FC } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';

import { action } from '../../../../store';
import { deleteCategoryItem } from '../../store/reducer';

import { CategoryItemProps } from './types';

export const CategoryItem: FC<CategoryItemProps> = ({ item, categoryType }) => {
  const onDeleteItem = () => {
    action(deleteCategoryItem({ path: categoryType, item }));
  };
  const { label } = item;

  return (
    <ListGroup.Item>
      <Row className={'align-items-center'}>
        <Col>
          <p className={'mb-0'}>{label}</p>
        </Col>
        <Col xs={'auto'} className={'ms-auto'}>
          <Button onClick={onDeleteItem} variant={'danger'}>
            Удалить
          </Button>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};
