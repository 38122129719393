import React, { FC, useCallback, useMemo } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { authActions } from '../../../feature/auth/store/actions';
import { selectUser } from '../../../feature/auth/store/selector';
import { selectSpend } from '../../../feature/categories/store/selectors';
import { action } from '../../../store';

const CustomToggle = React.forwardRef<HTMLButtonElement, { onClick: () => void }>(({ children, onClick }, ref) => {
  const user = useSelector(selectUser);

  return (
    <button className='btn' ref={ref} onClick={onClick}>
      {children}
      {user ? (
        user.photoURL ? (
          <Image width='40' height='40' src={user.photoURL} rounded />
        ) : (
          <div className='avatar'>{user.displayName?.charAt(0).toUpperCase()}</div>
        )
      ) : null}
    </button>
  );
});

export const Header: FC = () => {
  const user = useSelector(selectUser);
  const spend = useSelector(selectSpend);
  const total = useMemo(() => spend.reduce<number>((acc, val) => acc + val.sum, 0), [spend]);
  const onLogout = useCallback(() => action(authActions.logoutAction()), []);
  return (
    <header className='header'>
      <div className='header__inner'>
        <div className='row align-items-center'>
          <div className='col'>
            <p className='mb-0 header__spend'>
              В общем потрачено: <strong>{total} грн</strong>
            </p>
          </div>
          <div className='col-auto'>
            {user && (
              <Dropdown id='dropdown-basic-button' title='Dropdown button'>
                <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
                  <span className='me-2 avatar__name'> {user.displayName}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onLogout}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};
