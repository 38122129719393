import React, { FC } from 'react';

export const MonthIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.67999 17.7272L4.45624 15.4244H7.30824L8.08449 17.7272H9.11661L6.38824 10.0625H5.38774L2.65936 17.7272H3.67999ZM5.89949 11.2197L7.04374 14.6338H4.72074L5.87074 11.2197H5.89949ZM14.1594 11.8263H13.1876V15.4819C13.1876 16.4191 12.5925 16.9524 11.7444 16.9524C10.9696 16.9524 10.3356 16.5988 10.3356 15.4977V11.8263H9.36386V15.7737C9.36386 17.1264 10.235 17.8221 11.4526 17.8221C12.3956 17.8221 12.9519 17.4196 13.1603 16.9524H13.1991V17.7272H14.1594V11.8263ZM17.4052 19.0799C16.5959 19.0799 16.0971 18.6429 15.9893 18.1657H15.0017C15.1369 19.1475 15.9002 19.8893 17.3995 19.8893C18.7364 19.8893 19.9094 19.1317 19.9094 17.6209V11.8263H18.9764V12.6572H18.9491C18.6745 12.1569 18.0334 11.7372 17.2313 11.7372C15.8441 11.7372 14.8738 12.7132 14.8738 14.4483V14.9371C14.8738 16.7052 15.8556 17.6712 17.2313 17.6712C18.0334 17.6712 18.6803 17.25 18.916 16.7397H18.9448V17.6094C18.9448 18.5366 18.3367 19.0799 17.4052 19.0799ZM17.4167 12.5681C18.3482 12.5681 18.9434 13.3256 18.9434 14.5216V14.8853C18.9434 16.1043 18.3827 16.8461 17.4167 16.8461C16.422 16.8461 15.8384 16.1101 15.8384 14.8853V14.5216C15.8384 13.2739 16.422 12.5666 17.4167 12.5666V12.5681Z'
        fill={color}
      />
      <path
        d='M5.03125 0C5.22187 0 5.40469 0.0757252 5.53948 0.210517C5.67427 0.345309 5.75 0.528126 5.75 0.71875V1.4375H17.25V0.71875C17.25 0.528126 17.3257 0.345309 17.4605 0.210517C17.5953 0.0757252 17.7781 0 17.9688 0C18.1594 0 18.3422 0.0757252 18.477 0.210517C18.6118 0.345309 18.6875 0.528126 18.6875 0.71875V1.4375H20.125C20.8875 1.4375 21.6188 1.7404 22.1579 2.27957C22.6971 2.81874 23 3.55 23 4.3125V20.125C23 20.8875 22.6971 21.6188 22.1579 22.1579C21.6188 22.6971 20.8875 23 20.125 23H2.875C2.1125 23 1.38124 22.6971 0.842068 22.1579C0.302901 21.6188 0 20.8875 0 20.125V4.3125C0 3.55 0.302901 2.81874 0.842068 2.27957C1.38124 1.7404 2.1125 1.4375 2.875 1.4375H4.3125V0.71875C4.3125 0.528126 4.38823 0.345309 4.52302 0.210517C4.65781 0.0757252 4.84063 0 5.03125 0V0ZM2.875 2.875C2.49375 2.875 2.12812 3.02645 1.85853 3.29603C1.58895 3.56562 1.4375 3.93125 1.4375 4.3125V20.125C1.4375 20.5062 1.58895 20.8719 1.85853 21.1415C2.12812 21.411 2.49375 21.5625 2.875 21.5625H20.125C20.5062 21.5625 20.8719 21.411 21.1415 21.1415C21.411 20.8719 21.5625 20.5062 21.5625 20.125V4.3125C21.5625 3.93125 21.411 3.56562 21.1415 3.29603C20.8719 3.02645 20.5062 2.875 20.125 2.875H2.875Z'
        fill={color}
      />
      <path
        d='M3.59375 5.75C3.59375 5.55938 3.66948 5.37656 3.80427 5.24177C3.93906 5.10698 4.12188 5.03125 4.3125 5.03125H18.6875C18.8781 5.03125 19.0609 5.10698 19.1957 5.24177C19.3305 5.37656 19.4062 5.55938 19.4062 5.75V7.1875C19.4062 7.37812 19.3305 7.56094 19.1957 7.69573C19.0609 7.83052 18.8781 7.90625 18.6875 7.90625H4.3125C4.12188 7.90625 3.93906 7.83052 3.80427 7.69573C3.66948 7.56094 3.59375 7.37812 3.59375 7.1875V5.75Z'
        fill={color}
      />
    </svg>
  );
};
