import React, { PropsWithChildren } from 'react';
import { useTable, useSortBy, TableOptions, UseSortByColumnProps } from 'react-table';

export function Table({ columns, data }: PropsWithChildren<TableOptions<Record<string, unknown>>>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<Record<string, unknown>>(
    {
      columns,
      data,
    },
    ...[useSortBy]
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th
                    {...column.getHeaderProps(
                      (column as unknown as UseSortByColumnProps<Record<string, unknown>>).getSortByToggleProps()
                    )}
                  >
                    {column.render('Header')}
                    {(column as unknown as UseSortByColumnProps<Record<string, unknown>>).isSorted
                      ? (column as unknown as UseSortByColumnProps<Record<string, unknown>>).isSortedDesc
                        ? ' ↓'
                        : ' ↑'
                      : ''}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td style={{ color: cell.column.id === 'date' ? '#3699ff' : '#212529' }} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
