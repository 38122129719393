import { FC, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';

import { AddNewCategoryModal } from '../AddNewCategoryModal';
import { CategoryItem } from '../CategoryItem';

import { CategoryItemProps } from './types';

export const SettingsItem: FC<CategoryItemProps> = ({ label, categories, categoryType }) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const onNewCategoryClick = () => {
    setModalShow(true);
  };

  return (
    <div className='page-block'>
      <div className='page-block__header'>
        <h2>{label}</h2>
      </div>
      <div className='page-block__body'>
        {categories.length > 0 ? (
          <ListGroup className={'mb-3'}>
            {categories.map(i => (
              <CategoryItem item={i} key={i.latinName} categoryType={categoryType} />
            ))}
          </ListGroup>
        ) : (
          <p>У вас еще нет категорий</p>
        )}
      </div>
      <div className='page-block__bottom'>
        <Button onClick={onNewCategoryClick} variant={'primary'}>
          Добавить новую
        </Button>
        <AddNewCategoryModal
          show={modalShow}
          label={label}
          onHide={() => setModalShow(false)}
          categoryType={categoryType}
        />
      </div>
    </div>
  );
};
