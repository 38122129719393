import firebase from 'firebase/app';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getUserCategories, getCategoryItemRef, setUserCategories, UserCategories } from '../../../api/firestore.api';
import { ColSnap } from '../../../types';
import { error } from '../../../utils/logger';
import { selectUser } from '../../auth/store/selector';

import {
  getCategories,
  setAccumulationCategories,
  setCategories,
  setCategoriesError,
  setCategoriesIsLoading,
  addCategoryItemAction,
  setIncomeCategories,
  setSpendCategories,
  deleteCategoryItem,
} from './reducer';
import { selectCategories } from './selector';

function* getCategoriesSaga() {
  try {
    yield put(setCategoriesIsLoading(true));
    const user = (yield select(selectUser)) as firebase.User;

    const categories = (yield call(getUserCategories, user.uid)) as UserCategories | undefined;
    yield put(setSpendCategories(categories?.spend || []));
    yield put(setIncomeCategories(categories?.incomes || []));
    yield put(setAccumulationCategories(categories?.accumulation || []));
  } catch (e) {
    yield put(setCategoriesError((e as Error).message));
  } finally {
    yield put(setCategoriesIsLoading(false));
  }
}

function* addCategoryItemSaga(action: ReturnType<typeof addCategoryItemAction>) {
  try {
    const { path, item } = action.payload;
    const user = (yield select(selectUser)) as firebase.User;
    const categories = (yield select(selectCategories)) as UserCategories;
    categories[path] = [...categories[path], item];

    yield call(setUserCategories, user.uid, categories);
    yield put(setCategories(categories));
  } catch (e) {
    error(e as Error);
  }
}

function* deleteCategoryItemSaga(action: ReturnType<typeof deleteCategoryItem>) {
  try {
    const { path, item } = action.payload;
    const user = (yield select(selectUser)) as firebase.User;
    const categories = (yield select(selectCategories)) as UserCategories;
    categories[path] = categories[path].filter(i => i.latinName !== item.latinName);
    yield call(function* () {
      const snap: ColSnap = yield getCategoryItemRef(user.uid, path).where('name', '==', item.latinName).get();
      yield Promise.all(snap.docs.map(doc => doc.ref.delete()));
    });
    yield call(setUserCategories, user.uid, categories);
    yield put(setCategories(categories));
  } catch (e) {
    error(e as Error);
  }
}

export function* settingsSaga() {
  yield takeLatest(getCategories.type, getCategoriesSaga);
  yield takeLatest(addCategoryItemAction.type, addCategoryItemSaga);
  yield takeLatest(deleteCategoryItem.type, deleteCategoryItemSaga);
}
