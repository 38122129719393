import { CategoriesPath, settingItem } from '../feature/settings/store/types';
import { db } from '../firebase';

import { categoryItemConverter } from './converters';

export const getUserRef = (userUid: string) => db.collection('users').doc(userUid);

export const getUser = async (userUid: string) => {
  const userRef = await getUserRef(userUid).get();
  return userRef.data() as FirestoreUserData;
};

export const getUserCategories = async (userUid: string) => {
  const userRef = await getUserRef(userUid).get();
  const result = userRef.data() as FirestoreUserData;

  return result.categories;
};

export const setUserCategories = async (userUid: string, categories: UserCategories) => {
  await getUserRef(userUid).update({ categories });
};

export const getCategoryItemRef = (userUid: string, path: CategoriesPath) => {
  return getUserRef(userUid).collection(path).withConverter(categoryItemConverter);
};

export type FirestoreUserData = {
  authProvider: string;
  email: string;
  name: string;
  uid: string;
  categories?: UserCategories;
};

export type UserCategories = {
  incomes: settingItem[];
  spend: settingItem[];
  accumulation: settingItem[];
};
