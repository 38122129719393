import { put, select, takeEvery } from 'redux-saga/effects';

import { authActions } from '../../feature/auth/store/actions';
import { selectNotifications } from '../../feature/auth/store/selector';
import { NotificationItem } from '../../feature/auth/store/types';

function* addNotification(action: ReturnType<typeof authActions.addNotificationAction>) {
  const notifications = (yield select(selectNotifications)) as NotificationItem[];

  yield put(authActions.setNotifications([...notifications, action.payload]));
}

function* removeNotification({ payload }: ReturnType<typeof authActions.removeNotificationAction>) {
  const notifications = (yield select(selectNotifications)) as NotificationItem[];
  yield put(authActions.setNotifications(notifications.filter(i => i.id !== payload)));
}

export function* notificationSaga() {
  yield takeEvery(authActions.addNotificationAction, addNotification);
  yield takeEvery(authActions.removeNotificationAction, removeNotification);
}
