import firebase from 'firebase/app';
import { call, put } from 'redux-saga/effects';

import { auth, db } from '../../../firebase';
import { getUserFromFb } from '../../../sagas/auth/authUtils';
import { actions } from '../store/reducer';

const googleProvider = new firebase.auth.GoogleAuthProvider();

export function* setUserData() {
  const user = (yield call(getUserFromFb)) as firebase.User;

  yield put(actions.setUser(user));
}
export const signInWithGoogle = async () => {
  const res = await auth.signInWithPopup(googleProvider);
  const user = res.user;
  if (!user) {
    return;
  }
  const query = await db.collection('users').where('uid', '==', user?.uid).get();
  if (query.docs.length === 0) {
    await db.collection('users').doc(user.uid).set({
      uid: user.uid,
      name: user.displayName,
      authProvider: 'google',
      email: user.email,
    });
  }
  return user;
};

export const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  const res = await auth.createUserWithEmailAndPassword(email, password);
  const user = res.user;
  if (!user) {
    return;
  }
  await user.updateProfile({ displayName: name });
  await db.collection('users').doc(user.uid).set({
    uid: user.uid,
    name,
    authProvider: 'local',
    email,
  });
};

export const sendPasswordResetEmail = async (email: string) => {
  await auth.sendPasswordResetEmail(email);
};

export const loginWithEmailAndPassword = async (email: string, password: string) => {
  await auth.signInWithEmailAndPassword(email, password);
};

export const logout = async () => {
  await auth.signOut();
};
