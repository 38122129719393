import React from 'react';

function Loader() {
  return (
    <div className='d-flex vh-100 align-items-center justify-content-center'>
      <div className='loadingio-spinner-rolling-382cdap6rlp'>
        <div className='ldio-uf2l8kt5zyh'>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export { Loader };
