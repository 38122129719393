import firebase from 'firebase/app';
import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router as BrowserRouter } from 'react-router';

import App from './App';
import { db, firebaseApp } from './firebase';
import { history } from './history';
import { store } from './store';

export type firebaseContext = {
  firebaseApp: firebase.app.App;
  firestore: firebase.firestore.Firestore;
};

export const FirebaseContext = createContext<firebaseContext>({
  firebaseApp,
  firestore: db,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <FirebaseContext.Provider
        value={{
          firebaseApp,
          firestore: db,
        }}
      >
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </FirebaseContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
