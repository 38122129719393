import { call, put } from 'redux-saga/effects';

import { authChan, loggedIn } from '../../../sagas/auth/authUtils';
import { error } from '../../../utils/logger';
import {
  loginWithEmailAndPassword,
  logout,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithGoogle,
} from '../authUtils';

import { authActions } from './actions';

export function* googleLoginSaga() {
  try {
    yield call(signInWithGoogle);
    yield put(authChan, loggedIn({ redirectTo: 'welcome' }));
  } catch (e) {
    error(e);
  }
}

export function* emailRegisterSaga(action: ReturnType<typeof authActions.emailRegisterAction>) {
  try {
    const { email, name, password } = action.payload;
    yield call(registerWithEmailAndPassword, name, email, password);
    yield put(authChan, loggedIn({ redirectTo: 'welcome' }));
  } catch (e) {
    error(e);
  }
}

export function* emailLoginSaga(action: ReturnType<typeof authActions.emailLoginAction>) {
  try {
    const { email, password } = action.payload;
    yield call(loginWithEmailAndPassword, email, password);
    yield put(authChan, loggedIn({ redirectTo: 'welcome' }));
  } catch (e) {
    error(e);
  }
}

export function* logoutSaga() {
  try {
    yield logout();
  } catch (e) {
    error(e);
  }
}
export function* recoveryPasswordSaga(action: ReturnType<typeof authActions.recoveryPasswordAction>) {
  try {
    const { email } = action.payload;
    yield call(sendPasswordResetEmail, email);
  } catch (e) {
    error(e);
  }
}
