import { Saga } from '@redux-saga/types';
import { Location } from 'history';
import { buffers, eventChannel } from 'redux-saga';
import { call, cancelled, CancelledEffect, takeLatest } from 'redux-saga/effects';

import { history } from '../../history';
import { pathMatchesRoute } from '../../utils/helpers';
import { info } from '../../utils/logger';

import { RouteHandler } from './utils';

export const blocker = () => call(() => new Promise(() => ({})));

export function* onHistory(sagaWorker: Saga, historyName: string) {
  const historyChannel = eventChannel(emitter => {
    let location = history.location;
    emitter({ ...location });

    const unsubscribe = history.listen((newLocation: Location<string>) => {
      if (location.pathname !== newLocation.pathname) {
        location = newLocation;

        emitter({ ...location });
      }
    });
    return () => {
      unsubscribe();
    };
  }, buffers.expanding());

  try {
    yield takeLatest(historyChannel, sagaWorker);
    yield blocker();
  } finally {
    const isCancel = (yield cancelled()) as CancelledEffect;

    if (isCancel) {
      info(`History '${historyName}' has been canceled!`);
      historyChannel.close();
    }
  }
}

export function* onNavigate(location: Location<string>, routeHandlers: RouteHandler[], toRedirect: () => void) {
  const { pathname } = location;

  const routeHandler = routeHandlers.find(({ route }) => pathMatchesRoute(pathname, route));
  if (routeHandler) {
    const { saga, route } = routeHandler;

    yield call(saga, location, route);
  } else {
    yield call(toRedirect);
  }
}
