import { Action, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../sagas';

import reducer from './reducer';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const action = (actionToDispatch: Action) => {
  store.dispatch(actionToDispatch);
};

sagaMiddleware.run(rootSaga);
