import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase/app';

import { authInitialState, NotificationItem } from './types';

const initialState: authInitialState = {
  user: null,
  notifications: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<firebase.User | null>) {
      state.user = payload;
    },
    setNotifications: (state, { payload: notifications }: PayloadAction<NotificationItem[]>) => ({
      ...state,
      notifications,
    }),
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
