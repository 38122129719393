import { FC } from 'react';

import { authActions } from '../feature/auth/store/actions';
import { AccumulationsIcon } from '../icons/AccumulationIcon';
import { AllCategoriesIcon } from '../icons/AllCategoriesIcon';
import { AllTimeIcon } from '../icons/AllTimeIcon';
import { ClearDbIcon } from '../icons/ClearDBIcon';
import { DayIcon } from '../icons/DayIcon';
import { GitHubIcon } from '../icons/GitHubIcon';
import { GmailIcon } from '../icons/GmailIcon';
import { IncomingIcon } from '../icons/IncomingIcon';
import { InstagramIcon } from '../icons/InstagramIcon';
import { LogOutIcon } from '../icons/LogOutIcon';
import { MonthIcon } from '../icons/MonthIcon';
import { SettingsIcon } from '../icons/SettingsIcon';
import { SpendIcon } from '../icons/SpendIcon';
import { SupportIcon } from '../icons/SupportIcon';
import { TelegramIcon } from '../icons/TelegramIcon';
import { UserSettingsIcon } from '../icons/UserSettingsIcon';
import { store } from '../store';

import {
  ACCUMULATION_ROUTE,
  ALL_ROUTE,
  ALL_TIME_STATISTICS_ROUTE,
  CATEGORIES_ROUTE,
  CATEGORIES_SETTINGS_ROUTE,
  DAY_STATISTICS_ROUTE,
  GITHUB_LINK,
  GMAIL_LINK,
  INCOMING_ROUTE,
  INSTAGRAM_LINK,
  MONTH_STATISTICS_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  SUPPORT_DEVELOPER_ROUTE,
  TELEGRAM_LINK,
} from './routes';

type SidebarMenu = {
  name: string | null;
  children: SidebarItemType[];
};
export type SidebarItemType =
  | {
      isButton: false;
      Icon: FC<{ isActive?: boolean }>;
      title: string;
      path: string;
      external?: boolean;
    }
  | {
      isButton: true;
      Icon: FC<{ isActive?: boolean }>;
      title: string;
      onClick: () => void;
    };
export const sidebarLinks: SidebarMenu[] = [
  {
    name: 'Категории',
    children: [
      { isButton: false, title: 'Траты', path: CATEGORIES_ROUTE, Icon: SpendIcon },
      { isButton: false, title: 'Поступления', path: INCOMING_ROUTE, Icon: IncomingIcon },
      { isButton: false, title: 'Накопления', path: ACCUMULATION_ROUTE, Icon: AccumulationsIcon },
      { isButton: false, title: 'Все операции', path: ALL_ROUTE, Icon: AllCategoriesIcon },
    ],
  },
  {
    name: 'Настройки',
    children: [
      { isButton: false, title: 'Настройки категорий', path: CATEGORIES_SETTINGS_ROUTE, Icon: SettingsIcon },
      { isButton: false, title: 'Настройки профайла', path: PROFILE_SETTINGS_ROUTE, Icon: UserSettingsIcon },
      { isButton: true, title: 'Очистить БД', onClick: () => {}, Icon: ClearDbIcon },
    ],
  },
  {
    name: 'Статистика',
    children: [
      { isButton: false, title: 'За день', path: DAY_STATISTICS_ROUTE, Icon: DayIcon },
      { isButton: false, title: 'За месяц', path: MONTH_STATISTICS_ROUTE, Icon: MonthIcon },
      { isButton: false, title: 'За все время', path: ALL_TIME_STATISTICS_ROUTE, Icon: AllTimeIcon },
    ],
  },
  {
    name: 'Обратная связь',
    children: [
      { isButton: false, title: 'GitHub', path: GITHUB_LINK, Icon: GitHubIcon, external: true },
      { isButton: false, title: 'Telegram', path: TELEGRAM_LINK, Icon: TelegramIcon, external: true },
      { isButton: false, title: 'Instagram', path: INSTAGRAM_LINK, Icon: InstagramIcon, external: true },
      { isButton: false, title: 'Gmail', path: GMAIL_LINK, Icon: GmailIcon, external: true },
    ],
  },
  {
    name: null,
    children: [
      { isButton: true, title: 'Выйти', onClick: () => store.dispatch(authActions.logoutAction()), Icon: LogOutIcon },
      { isButton: false, title: 'Поддержать разработку', path: SUPPORT_DEVELOPER_ROUTE, Icon: SupportIcon },
    ],
  },
];
