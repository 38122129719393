import { Switch, Route } from 'react-router';

import { Messages } from './components/atom/Messages';
import { Loader } from './components/loader';
import { Layout } from './components/wrappers/Layout';
import { Wrapper } from './components/wrappers/Wrapper';
import {
  ACCUMULATION_ROUTE,
  ALL_ROUTE,
  CATEGORIES_ROUTE,
  CATEGORIES_SETTINGS_ROUTE,
  INCOMING_ROUTE,
  INDEX_ROUTE,
  LOGIN_ROUTE,
  PRIVATE_ROUTES,
  RECOVERY_PASSWORD_ROUTE,
  REGISTER_ROUTE,
} from './constants/routes';
import { LoginPage } from './feature/auth/screen/LoginPage';
import { RecoveryPasswordPage } from './feature/auth/screen/RecoveryPasswordPage';
import { SignUpPage } from './feature/auth/screen/SignUpPage';
import { AccumulationScreen } from './feature/categories/screen/AccumulationScreen';
import { AllCategoriesScreen } from './feature/categories/screen/AllCategories';
import { IncomesScreen } from './feature/categories/screen/IncomesScreen';
import { SpendScreen } from './feature/categories/screen/SpendScreen';
import { SettingsPage } from './feature/settings/screen/SettingsPage';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './styles/styles.scss';
import './style.css';

function App() {
  return (
    <div className='page d-flex'>
      <Switch>
        <Route path={PRIVATE_ROUTES}>
          <Wrapper>
            <Layout>
              <Switch>
                <Route exact path={CATEGORIES_ROUTE}>
                  <SpendScreen />
                </Route>
                <Route path={INCOMING_ROUTE}>
                  <IncomesScreen />
                </Route>
                <Route path={ACCUMULATION_ROUTE}>
                  <AccumulationScreen />
                </Route>
                <Route path={ALL_ROUTE}>
                  <AllCategoriesScreen />
                </Route>
                <Route path={CATEGORIES_SETTINGS_ROUTE}>
                  <SettingsPage />
                </Route>
                <Route>
                  <p>В разработке</p>
                </Route>
              </Switch>
            </Layout>
          </Wrapper>
        </Route>

        <Route path={LOGIN_ROUTE}>
          <LoginPage />
        </Route>
        <Route path={REGISTER_ROUTE} component={SignUpPage} />
        <Route path={RECOVERY_PASSWORD_ROUTE} component={RecoveryPasswordPage} />
        <Route path={INDEX_ROUTE}>
          <div className='bg-white w-100 h-100 d-flex align-items-center justify-content-center'>
            <Loader />
          </div>
        </Route>
        <Route>
          <p>404 не найдено</p>
        </Route>
      </Switch>
      <Messages />
    </div>
  );
}

export default App;
