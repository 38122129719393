import { channel } from '@redux-saga/core';
import { Channel } from '@redux-saga/types';
import firebase from 'firebase';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authActions } from '../../feature/auth/store/actions';
import { auth } from '../../firebase';
import { Saga } from '../../types';
import { error } from '../../utils/logger';
import { toLogin, toWelcome } from '../routes/utils';
// import { error } from '../../utils/logger';

export type LoggedState = {
  type: string;
  actions?: LoggedActions;
};

export type LoggedActions = {
  redirectTo: 'login' | 'welcome';
};

const LOGGED_IN = 'LOGGED_IN';
const LOGGED_OUT = 'LOGGED_OUT';

export const authChan: Channel<LoggedState> = channel();
export const loggedIn = (actions?: LoggedActions): LoggedState => ({ type: LOGGED_IN, actions });
export const loggedOut = (actions?: LoggedActions): LoggedState => ({ type: LOGGED_OUT, actions });

const getUser = () =>
  new Promise<firebase.User | null>((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(
      user => {
        resolve(user);
        unsubscribe();
      },
      e => {
        reject(e);
        unsubscribe();
      }
    );
  });

export async function getUserFromFb() {
  try {
    const user: firebase.User | null = await getUser();

    if (!user) {
      throw new Error('Something went wrong! There is no logged user in the system!');
    }
    return user;
  } catch (e: unknown) {
    error(e);
    throw e;
  }
}
function* beforeAuth(actions?: LoggedActions) {
  if (!actions) return;

  switch (actions.redirectTo) {
    case 'login':
      yield call(toLogin);
      break;
    case 'welcome':
      yield call(toWelcome);
      break;
    default:
      return;
  }
}
export function* onAuth(handleSignedIn: Saga, handleSignedOut: Saga) {
  const currentUser = (yield getUser()) as firebase.User | null;

  if (currentUser) {
    yield put(authChan, loggedIn());

    yield put(authActions.setUser(currentUser));
  } else {
    yield put(authChan, loggedOut());
  }

  yield takeLatest(authChan, function* ({ actions, type }) {
    yield call(beforeAuth, actions);

    switch (type) {
      case LOGGED_IN:
        yield call(handleSignedIn);

        break;
      case LOGGED_OUT:
        yield call(handleSignedOut);
        break;
      default:
        throw new Error('Unexpected value in authChan');
    }
  });
}

export function* signOut() {
  yield auth.signOut();

  yield put(authChan, loggedOut({ redirectTo: 'login' }));
}
