import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Loader } from '../../../../components/loader';
import { SettingsItem } from '../../components/SettingsItem';
import {
  selectAccumulationCategories,
  selectCategoriesError,
  selectIncomeCategories,
  selectIsCategoriesLoading,
  selectSpendCategories,
} from '../../store/selector';

export const SettingsPage = () => {
  const isCategoriesLoading = useSelector(selectIsCategoriesLoading);
  const categoriesError = useSelector(selectCategoriesError);
  const spendCategories = useSelector(selectSpendCategories);
  const incomesCategories = useSelector(selectIncomeCategories);
  const accumulationCategories = useSelector(selectAccumulationCategories);

  if (isCategoriesLoading) {
    return <Loader />;
  }
  if (categoriesError) {
    return <h1>something wrong</h1>;
  }
  return (
    <div>
      <h1>Настройка категорий</h1>
      <Row>
        <Col sm={6}>
          <SettingsItem label='Траты' categories={spendCategories} categoryType={'spend'} />
        </Col>
        <Col sm={6}>
          <SettingsItem label='Поступления' categories={incomesCategories} categoryType={'incomes'} />
        </Col>
        <Col sm={6}>
          <SettingsItem label='Накопления' categories={accumulationCategories} categoryType={'accumulation'} />
        </Col>
      </Row>
    </div>
  );
};
