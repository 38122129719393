import firebase from 'firebase';
import { call, put, select } from 'redux-saga/effects';

import { getCategoryItemRef } from '../../api/firestore.api';
import { CURRENT_DATA, END_RANGE_DATA, START_RANGE_DATA } from '../../constants';
import { selectUser } from '../../feature/auth/store/selector';
import { categoriesActions } from '../../feature/categories/store/reducer';
import { CategoriesItem } from '../../feature/categories/types';
import { CategoriesPath } from '../../feature/settings/store/types';
import { ColSnap } from '../../types';
import { onSnapshot, queryColChannel } from '../utils';

const getData = async (uid: string, collectionName: CategoriesPath) => {
  return (
    await getCategoryItemRef(uid, collectionName)
      .where('createdAt', '>=', START_RANGE_DATA)
      .where('createdAt', '<=', END_RANGE_DATA)
      .orderBy('createdAt', 'desc')
      .get()
  ).docs.map(doc => doc.data());
};

export function* setInitialData() {
  const user = (yield select(selectUser)) as firebase.User | null;
  if (!user) {
    throw new Error('User not found');
  }

  const spend = (yield call(getData, user.uid, 'spend')) as CategoriesItem[];
  const incomes = (yield call(getData, user.uid, 'incomes')) as CategoriesItem[];
  const accumulation = (yield call(getData, user.uid, 'accumulation')) as CategoriesItem[];
  yield put(categoriesActions.setAccumulation(accumulation.map(i => ({ ...i, categoryPath: 'accumulation' }))));
  yield put(categoriesActions.setIncomes(incomes.map(i => ({ ...i, categoryPath: 'incomes' }))));
  yield put(categoriesActions.setSpend(spend.map(i => ({ ...i, categoryPath: 'spend' }))));
}

function* updateSpend(snapshot: ColSnap) {
  const items = snapshot.docs.map(snap => snap.data()) as CategoriesItem[];

  yield put(categoriesActions.setSpend(items.map(i => ({ ...i, categoryPath: 'spend' }))));
}

export function* watchSpendWorker() {
  const user = (yield select(selectUser)) as firebase.User | null;
  if (!user) {
    throw new Error('User not found');
  }

  const spendRef = getCategoryItemRef(user.uid, 'spend')
    .where('createdAt', '>=', START_RANGE_DATA)
    .where('createdAt', '<=', END_RANGE_DATA)
    .orderBy('createdAt', 'desc');
  const spendChannel = queryColChannel(spendRef, false);

  yield call(onSnapshot, spendChannel, updateSpend, 'Spend');
}

function* updateIncomes(snapshot: ColSnap) {
  const items = snapshot.docs.map(snap => snap.data()) as CategoriesItem[];

  yield put(categoriesActions.setIncomes(items.map(i => ({ ...i, categoryPath: 'incomes' }))));
}

export function* watchIncomesWorker() {
  const user = (yield select(selectUser)) as firebase.User | null;
  if (!user) {
    throw new Error('User not found');
  }
  const incomesRef = getCategoryItemRef(user.uid, 'incomes')
    .where('createdAt', '>=', START_RANGE_DATA)
    .where('createdAt', '<=', END_RANGE_DATA)
    .orderBy('createdAt', 'desc');
  const incomesChannel = queryColChannel(incomesRef, false);

  yield call(onSnapshot, incomesChannel, updateIncomes, 'Incomes');
}

function* updateAccumulation(snapshot: ColSnap) {
  const items = snapshot.docs.map(snap => snap.data()) as CategoriesItem[];

  yield put(categoriesActions.setAccumulation(items.map(i => ({ ...i, categoryPath: 'accumulation' }))));
}

export function* watchAccumulationWorker() {
  const user = (yield select(selectUser)) as firebase.User | null;
  if (!user) {
    throw new Error('User not found');
  }
  const accumulationRef = getCategoryItemRef(user.uid, 'accumulation')
    .where('createdAt', '>=', START_RANGE_DATA)
    .where('createdAt', '<=', END_RANGE_DATA)
    .orderBy('createdAt', 'desc');
  const accumulationChannel = queryColChannel(accumulationRef, false);

  yield call(onSnapshot, accumulationChannel, updateAccumulation, 'Accumulation');
}
