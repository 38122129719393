import React, { FC } from 'react';

export const UserSettingsIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.5625 7.1875V5.75H20.0524C19.9597 5.30008 19.7811 4.87227 19.5263 4.49003L20.5972 3.41909L19.5809 2.40278L18.51 3.47372C18.1277 3.21893 17.6999 3.04029 17.25 2.94759V1.4375H15.8125V2.94759C15.3626 3.04029 14.9348 3.21893 14.5525 3.47372L13.4816 2.40278L12.4653 3.41909L13.5362 4.49003C13.2814 4.87227 13.1028 5.30008 13.0101 5.75H11.5V7.1875H13.0101C13.1028 7.63742 13.2814 8.06523 13.5362 8.44747L12.4653 9.51841L13.4816 10.5347L14.5525 9.46378C14.9348 9.71857 15.3626 9.89721 15.8125 9.98991V11.5H17.25V9.98991C17.6999 9.89721 18.1277 9.71857 18.51 9.46378L19.5809 10.5347L20.5972 9.51841L19.5263 8.44747C19.7811 8.06523 19.9597 7.63742 20.0524 7.1875H21.5625ZM16.5312 8.625C16.1048 8.625 15.6879 8.49854 15.3333 8.26161C14.9787 8.02467 14.7023 7.68791 14.5391 7.29391C14.3759 6.89991 14.3332 6.46636 14.4164 6.04809C14.4996 5.62982 14.705 5.24561 15.0066 4.94405C15.3081 4.64249 15.6923 4.43713 16.1106 4.35393C16.5289 4.27073 16.9624 4.31343 17.3564 4.47663C17.7504 4.63984 18.0872 4.91621 18.3241 5.2708C18.561 5.6254 18.6875 6.04228 18.6875 6.46875C18.6869 7.04045 18.4596 7.58857 18.0553 7.99282C17.6511 8.39707 17.1029 8.62443 16.5312 8.625Z'
        fill={color}
      />
      <path
        d='M11.5 21.5625H10.0625V17.9688C10.0619 17.3971 9.83457 16.8489 9.43032 16.4447C9.02607 16.0404 8.47795 15.8131 7.90625 15.8125H5.03125C4.45955 15.8131 3.91143 16.0404 3.50718 16.4447C3.10293 16.8489 2.87557 17.3971 2.875 17.9688V21.5625H1.4375V17.9688C1.43864 17.016 1.81763 16.1026 2.49135 15.4288C3.16506 14.7551 4.07848 14.3761 5.03125 14.375H7.90625C8.85902 14.3761 9.77244 14.7551 10.4462 15.4288C11.1199 16.1026 11.4989 17.016 11.5 17.9688V21.5625Z'
        fill={color}
      />
      <path
        d='M6.46875 7.1875C6.89522 7.1875 7.31211 7.31396 7.6667 7.55089C8.02129 7.78783 8.29767 8.12459 8.46087 8.51859C8.62407 8.91259 8.66677 9.34614 8.58357 9.76441C8.50037 10.1827 8.29501 10.5669 7.99345 10.8684C7.69189 11.17 7.30769 11.3754 6.88942 11.4586C6.47114 11.5418 6.03759 11.4991 5.64359 11.3359C5.24959 11.1727 4.91283 10.8963 4.6759 10.5417C4.43896 10.1871 4.3125 9.77022 4.3125 9.34375C4.3125 8.77188 4.53968 8.22343 4.94405 7.81905C5.34843 7.41468 5.89688 7.1875 6.46875 7.1875ZM6.46875 5.75C5.75798 5.75 5.06316 5.96077 4.47217 6.35566C3.88118 6.75054 3.42056 7.31181 3.14856 7.96848C2.87656 8.62515 2.80539 9.34774 2.94405 10.0449C3.08272 10.742 3.42499 11.3823 3.92759 11.8849C4.43018 12.3875 5.07053 12.7298 5.76765 12.8684C6.46477 13.0071 7.18735 12.9359 7.84402 12.6639C8.50069 12.3919 9.06196 11.9313 9.45684 11.3403C9.85173 10.7493 10.0625 10.0545 10.0625 9.34375C10.0625 8.87181 9.96955 8.4045 9.78894 7.96848C9.60834 7.53247 9.34363 7.1363 9.00992 6.80259C8.67621 6.46887 8.28003 6.20416 7.84402 6.02356C7.40801 5.84295 6.94069 5.75 6.46875 5.75Z'
        fill={color}
      />
    </svg>
  );
};
