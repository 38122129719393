import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { FC, FormEvent, useState } from 'react';
import { Form, Modal, Stack } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';

import { action } from '../../../../store';
import { createNotification } from '../../../../utils/helpers';
import { authActions } from '../../../auth/store/actions';
import { addCategoryItemAction } from '../../store/reducer';
import { settingItem } from '../../store/types';

import { AddNewCategoryModalProps } from './types';

export const AddNewCategoryModal: FC<AddNewCategoryModalProps> = ({ label, categoryType, ...props }) => {
  const [newLabel, setNewLabel] = useState<string>('');

  const addCategory = () => {
    if (newLabel.trim().length === 0) {
      action(authActions.addNotificationAction(createNotification('danger', 'Введите название категории')));
      return;
    }
    const itemLabel = newLabel.split(' ').join('_');
    const item: settingItem = {
      label: newLabel,
      latinName: new CyrillicToTranslit().transform(itemLabel),
    };
    action(addCategoryItemAction({ path: categoryType, item }));
    setNewLabel('');
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addCategory();
  };

  return (
    <Modal {...props} size='sm' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Добавить</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='mb-3'>{label}</h4>
        <Form onSubmit={onSubmit}>
          <Stack>
            <Form.Group>
              <Form.Control
                value={newLabel}
                onInput={e => setNewLabel(e.currentTarget.value)}
                type='text'
                placeholder='Введите название категории'
              />
            </Form.Group>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={addCategory} className='ms-auto' variant='primary' type='submit'>
          Добавить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
