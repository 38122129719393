import { FormEvent, useCallback, useState } from 'react';

import { Link } from '../../../../components/atom/Link';
import { action } from '../../../../store';
import { warn } from '../../../../utils/logger';
import { authActions } from '../../store/actions';

export const RecoveryPasswordPage = () => {
  const [email, setEmail] = useState<string>('');

  const onEmailInput = useCallback((e: FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value), []);

  const onRecoverySubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!(email.split('@').length === 2)) {
        warn('invalid email');
        return;
      }
      action(authActions.recoveryPasswordAction({ email }));
    },
    [email]
  );
  return (
    <div className='auth d-flex mh-100 justify-content-center align-items-center'>
      <form onSubmit={onRecoverySubmit} className='auth__inner d-flex flex-column align-items-center'>
        <h1>Забыли пароль!</h1>

        <div className='auth__inputs'>
          <label>
            <input
              onInput={onEmailInput}
              type='email'
              className='form-control auth__input'
              placeholder='Введите ваш E-mail'
            />
          </label>

          <button type={'submit'} className='btn btn-primary auth__btn'>
            Восстановить
          </button>
        </div>
        <Link
          className='mt-3'
          href='#'
          onClick={() => {
            action(authActions.toLoginClick());
          }}
        >
          Назад на логин
        </Link>
      </form>
    </div>
  );
};
