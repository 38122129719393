import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectIsAppLoading } from '../../../feature/categories/store/selectors';
import { selectIsCategoriesLoading } from '../../../feature/settings/store/selector';
import { Loader } from '../../loader';

export const Wrapper: FC = ({ children }) => {
  const isLoading = useSelector(selectIsAppLoading);
  const isCategoriesLoading = useSelector(selectIsCategoriesLoading);
  if (isLoading || isCategoriesLoading) {
    return (
      <div className='bg-white w-100 h-100 d-flex align-items-center justify-content-center'>
        <Loader />
      </div>
    );
  }

  return <>{children}</>;
};
