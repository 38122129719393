import { takeLatest } from 'redux-saga/effects';

import { authActions } from '../../../feature/auth/store/actions';
import { recoveryPasswordSaga } from '../../../feature/auth/store/saga';
import { toLogin } from '../utils';

export function* recoveryPasswordWorker() {
  yield takeLatest(authActions.toLoginClick, toLogin);
  yield takeLatest(authActions.recoveryPasswordAction, recoveryPasswordSaga);
}
