import React, { ReactElement, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { authActions } from '../../../feature/auth/store/actions';
import { selectNotifications } from '../../../feature/auth/store/selector';
import { action } from '../../../store';

const Messages: React.FC = (): ReactElement => {
  const messages = useSelector(selectNotifications);

  const ref = useRef(null);

  const onDeleteMessage = (id: number) => {
    action(authActions.removeNotificationAction(id));
  };
  return (
    <TransitionGroup className='messages'>
      {messages.map(message => {
        return (
          <CSSTransition
            key={message.id}
            timeout={500}
            classNames='show'
            nodeRef={ref}
            appear={true}
            onEnter={() => {
              setTimeout(() => {
                action(authActions.removeNotificationAction(message.id));
              }, 2000);
            }}
          >
            <button
              className={`alert d-block alert-${message.type}`}
              role='alert'
              aria-live='assertive'
              aria-atomic='true'
              ref={ref}
              onClick={() => onDeleteMessage(message.id)}
            >
              <p className='mb-0'>{message.message}</p>
            </button>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};

export { Messages };
