import { RootState } from '../../../store';

export const selectIncomeCategories = ({ settings }: RootState) => settings.incomeCategories;
export const selectSpendCategories = ({ settings }: RootState) => settings.spendCategories;
export const selectAccumulationCategories = ({ settings }: RootState) => settings.accumulationCategories;

export const selectIsCategoriesLoading = ({ settings }: RootState) => settings.isLoading;
export const selectCategoriesError = ({ settings }: RootState) => settings.error;

export const selectCategories = ({
  settings: { spendCategories, incomeCategories, accumulationCategories },
}: RootState) => ({ spend: spendCategories, incomes: incomeCategories, accumulation: accumulationCategories });
