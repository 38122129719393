import { buffers, EventChannel, eventChannel } from 'redux-saga';
import { cancelled, CancelledEffect, takeLatest } from 'redux-saga/effects';

import { ColRef, ColSnap, DocRef, DocSnap, Saga } from '../types';
import { info } from '../utils/logger';

import { blocker } from './routes';

export const queryDocChannel = (queryRef: DocRef) =>
  eventChannel<DocSnap>(emitter => queryRef.onSnapshot(emitter), buffers.expanding());

export const queryColChannel = (queryRef: ColRef, emitImmediately = true) => {
  let firstEmission = true;

  return eventChannel<ColSnap>(
    emitter =>
      queryRef.onSnapshot(snapshot => {
        if (!firstEmission || emitImmediately) emitter(snapshot);
        firstEmission = false;
      }),
    buffers.expanding()
  );
};

export function* onSnapshot(channel: EventChannel<DocSnap | ColSnap>, sagaWorker: Saga, snapshotName = '') {
  try {
    yield takeLatest(channel, sagaWorker);

    yield blocker();
  } finally {
    const isCancel = (yield cancelled()) as CancelledEffect;

    if (isCancel) {
      info(`Snapshot '${snapshotName}' has been canceled!`);
      channel.close();
    }
  }
}
