import React, { FC } from 'react';

export const LogOutIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.15924 19.1667H4.32591C3.81758 19.1667 3.33006 18.9647 2.97062 18.6053C2.61117 18.2459 2.40924 17.7583 2.40924 17.25V5.75001C2.40924 5.24168 2.61117 4.75417 2.97062 4.39472C3.33006 4.03528 3.81758 3.83334 4.32591 3.83334H8.15924V5.75001H4.32591V17.25H8.15924V19.1667Z'
        fill={color}
      />
      <path
        d='M13.2652 16.6606L14.6261 15.3113L10.8694 11.523H19.6324C19.8866 11.523 20.1303 11.4221 20.31 11.2423C20.4898 11.0626 20.5907 10.8189 20.5907 10.5647C20.5907 10.3105 20.4898 10.0668 20.31 9.88705C20.1303 9.70732 19.8866 9.60636 19.6324 9.60636H10.8521L14.6663 5.82382L13.317 4.46298L7.19226 10.5359L13.2652 16.6606Z'
        fill={color}
      />
    </svg>
  );
};
