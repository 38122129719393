export const navbarLinks = [
  { title: 'Главная', path: '/' },
  { title: 'Настройки', path: '/settings' },
];

export const NOT_FOUND_ROUTE = '/404';
export const INDEX_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const RECOVERY_PASSWORD_ROUTE = '/recovery-password';

export const CATEGORIES_ROUTE = '/categories/';
export const INCOMING_ROUTE = '/categories/incoming';
export const ACCUMULATION_ROUTE = '/categories/accumulation';
export const ALL_ROUTE = '/categories/all';

export const CATEGORIES_SETTINGS_ROUTE = '/settings/categories';
export const PROFILE_SETTINGS_ROUTE = '/settings/profile';

export const DAY_STATISTICS_ROUTE = '/statistic/day';
export const MONTH_STATISTICS_ROUTE = '/statistic/month';
export const ALL_TIME_STATISTICS_ROUTE = '/statistic/all';
export const GITHUB_LINK = 'https://github.com/zhebil';
export const TELEGRAM_LINK = 'https://t.me/zhebil';
export const INSTAGRAM_LINK = 'https://www.instagram.com/zhebil/';
export const GMAIL_LINK = 'mailto:jbilyk1996@gmail.com';

export const SUPPORT_DEVELOPER_ROUTE = '/support/developer';

export const PUBLIC_ROUTES = [LOGIN_ROUTE, RECOVERY_PASSWORD_ROUTE, REGISTER_ROUTE];

export const PRIVATE_ROUTES = [
  CATEGORIES_ROUTE,
  INCOMING_ROUTE,
  ACCUMULATION_ROUTE,
  ALL_ROUTE,
  CATEGORIES_SETTINGS_ROUTE,
  PROFILE_SETTINGS_ROUTE,
  DAY_STATISTICS_ROUTE,
  MONTH_STATISTICS_ROUTE,
  ALL_TIME_STATISTICS_ROUTE,
  GITHUB_LINK,
  TELEGRAM_LINK,
  INSTAGRAM_LINK,
  GITHUB_LINK,
  SUPPORT_DEVELOPER_ROUTE,
];
