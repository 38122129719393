import { Location } from 'history';
import { call } from 'redux-saga/effects';

import { onHistory, onNavigate } from './routes';
import { publicRouteHandlers } from './routes/routeHandlers';
import { toLogin } from './routes/utils';

export function* handleSignedOut() {
  yield call(
    onHistory,
    function* (location: Location<string>) {
      yield call(onNavigate, location, publicRouteHandlers, toLogin);
    },
    'Public'
  );
}
