import React, { FC } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { SpendIcon } from '../../../../icons/SpendIcon';
import { action } from '../../../../store';
import { CategoriesPath } from '../../../settings/store/types';
import { categoriesActions } from '../../store/reducer';
import { CategoriesItem } from '../../types';

const colorsDictionary: { [key in CategoriesPath]: string } = {
  incomes: '#1BC5BD',
  accumulation: '#8950FC',
  spend: '#F64E60',
};

export const LastItems: FC<{ items: CategoriesItem[] }> = ({ items }) => {
  return (
    <div className='last-items'>
      <ul className='last-items__list'>
        {items.length > 0
          ? items.map(item => {
              return (
                <li key={item.id} className={`last-items__item last-items__item--${item.categoryPath}`}>
                  <div className='last-items__icon'>
                    <SpendIcon iconColor={colorsDictionary[item.categoryPath]} />
                  </div>
                  <div className='last-items__text'>
                    <p className='last-items__label'>{item.label}</p>
                    <p className='last-items__sum'>
                      {item.sum}{' '}
                      {item.comment && (
                        <OverlayTrigger
                          key={item.id}
                          placement={'auto'}
                          overlay={<Tooltip id={item.id}>{item.comment}</Tooltip>}
                        >
                          <Badge bg='dark'>i</Badge>
                        </OverlayTrigger>
                      )}
                    </p>
                    <time className='last-items__date'>{item.createdAt.toDate().toLocaleDateString()}</time>
                  </div>

                  <button
                    onClick={() => {
                      action(categoriesActions.deleteCategoryItem({ id: item.id, path: item.categoryPath }));
                    }}
                    type='button'
                    className='last-items__close'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};
