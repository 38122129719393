import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserCategories } from '../../../api/firestore.api';

import { setCategoryItemPayload, settingItem, settingsInitialState } from './types';

const initialState: settingsInitialState = {
  isLoading: false,
  error: '',
  spendCategories: [],
  incomeCategories: [],
  accumulationCategories: [],
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSpendCategories(state, { payload }: PayloadAction<settingItem[]>) {
      state.spendCategories = payload;
    },
    setIncomeCategories(state, { payload }: PayloadAction<settingItem[]>) {
      state.incomeCategories = payload;
    },
    setAccumulationCategories(state, { payload }: PayloadAction<settingItem[]>) {
      state.accumulationCategories = payload;
    },
    setCategoriesIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setCategoriesError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    setCategories(state, { payload }: PayloadAction<UserCategories>) {
      state.spendCategories = payload.spend;
      state.incomeCategories = payload.incomes;
      state.accumulationCategories = payload.accumulation;
    },
  },
});

export const {
  setAccumulationCategories,
  setIncomeCategories,
  setSpendCategories,
  setCategoriesError,
  setCategoriesIsLoading,
  setCategories,
} = settingsSlice.actions;

export const getCategories = createAction('settings/getCategories');
export const addCategoryItemAction = createAction<setCategoryItemPayload>('settings/setCategoryItem');
export const deleteCategoryItem = createAction<setCategoryItemPayload>('settings/deleteCategoryItem');

export default settingsSlice.reducer;
