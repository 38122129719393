import React, { FC } from 'react';

export const DayIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3_155)'>
        <path
          d='M18.8472 0C19.9486 0 21.0049 0.437523 21.7837 1.21632C22.5625 1.99512 23 3.05139 23 4.15278V18.8472C23 19.9486 22.5625 21.0049 21.7837 21.7837C21.0049 22.5625 19.9486 23 18.8472 23H4.15278C3.05139 23 1.99512 22.5625 1.21632 21.7837C0.437523 21.0049 0 19.9486 0 18.8472V4.15278C0 3.05139 0.437523 1.99512 1.21632 1.21632C1.99512 0.437523 3.05139 0 4.15278 0H18.8472ZM18.8472 1.91667H4.15278C3.55972 1.91667 2.99096 2.15226 2.57161 2.57161C2.15226 2.99096 1.91667 3.55972 1.91667 4.15278V18.8472C1.91667 20.0816 2.91844 21.0833 4.15278 21.0833H18.8472C19.4403 21.0833 20.009 20.8477 20.4284 20.4284C20.8477 20.009 21.0833 19.4403 21.0833 18.8472V4.15278C21.0833 3.55972 20.8477 2.99096 20.4284 2.57161C20.009 2.15226 19.4403 1.91667 18.8472 1.91667Z'
          fill={color}
        />
        <path
          d='M13.0972 6.38889C13.3514 6.38889 13.5952 6.48986 13.7749 6.66958C13.9546 6.84931 14.0556 7.09306 14.0556 7.34723V10.8611H15.9722V7.34723C15.9722 7.09306 16.0732 6.84931 16.2529 6.66958C16.4327 6.48986 16.6764 6.38889 16.9306 6.38889C17.1847 6.38889 17.4285 6.48986 17.6082 6.66958C17.788 6.84931 17.8889 7.09306 17.8889 7.34723V15.6144C17.8889 15.8686 17.788 16.1124 17.6082 16.2921C17.4285 16.4718 17.1847 16.5728 16.9306 16.5728C16.6764 16.5728 16.4327 16.4718 16.2529 16.2921C16.0732 16.1124 15.9722 15.8686 15.9722 15.6144V12.7778H13.0972C12.8431 12.7778 12.5993 12.6768 12.4196 12.4971C12.2399 12.3174 12.1389 12.0736 12.1389 11.8194V7.34723C12.1389 7.09306 12.2399 6.84931 12.4196 6.66958C12.5993 6.48986 12.8431 6.38889 13.0972 6.38889Z'
          fill={color}
        />
        <path
          d='M5.74996 7.33956C6.19335 6.85145 6.91913 6.38889 7.98607 6.38889C9.45807 6.38889 10.4062 7.40089 10.7397 8.51128C11.063 9.59356 10.8675 10.9327 9.94746 11.8565C9.63185 12.1734 9.26001 12.4609 8.93418 12.7126L8.88307 12.7522C8.52529 13.0282 8.20585 13.2787 7.92474 13.5572C7.5989 13.8843 7.33185 14.2408 7.17724 14.6944H9.90274C10.1569 14.6944 10.4007 14.7954 10.5804 14.9751C10.7601 15.1549 10.8611 15.3986 10.8611 15.6528C10.8611 15.9069 10.7601 16.1507 10.5804 16.3304C10.4007 16.5101 10.1569 16.6111 9.90274 16.6111H6.0694C5.81524 16.6111 5.57148 16.5101 5.39176 16.3304C5.21204 16.1507 5.11107 15.9069 5.11107 15.6528C5.11107 14.0594 5.78063 12.9912 6.57285 12.2015C6.95107 11.8233 7.36252 11.5051 7.71007 11.2355L7.7139 11.2329C8.07935 10.9506 8.36302 10.7308 8.58918 10.5046C8.93674 10.1545 9.05813 9.57823 8.90352 9.06073C8.75657 8.57389 8.43074 8.30556 7.98607 8.30556C7.53502 8.30556 7.30246 8.48062 7.16829 8.62884C7.09027 8.71557 7.02683 8.81439 6.98046 8.92145L6.9779 8.92528C6.89772 9.16471 6.72629 9.36282 6.50086 9.47656C6.27542 9.5903 6.01422 9.61047 5.77399 9.5327C5.53377 9.45492 5.33395 9.28549 5.21795 9.06121C5.10196 8.83693 5.07916 8.57594 5.15451 8.33495L5.19924 8.21228C5.33053 7.89178 5.51643 7.5965 5.74868 7.33956H5.74996Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3_155'>
          <rect width='23' height='23' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
