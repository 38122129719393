import React, { FC } from 'react';

export const GmailIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.1667 17.25H17.25V8.86459L11.5 12.4583L5.75002 8.86459V17.25H3.83335V5.75001H4.98335L11.5 9.82293L18.0167 5.75001H19.1667V17.25ZM19.1667 3.83334H3.83335C2.7696 3.83334 1.91669 4.68626 1.91669 5.75001V17.25C1.91669 17.7583 2.11862 18.2459 2.47807 18.6053C2.83751 18.9647 3.32502 19.1667 3.83335 19.1667H19.1667C19.675 19.1667 20.1625 18.9647 20.522 18.6053C20.8814 18.2459 21.0834 17.7583 21.0834 17.25V5.75001C21.0834 5.24168 20.8814 4.75417 20.522 4.39472C20.1625 4.03528 19.675 3.83334 19.1667 3.83334Z'
        fill={color}
      />
    </svg>
  );
};
