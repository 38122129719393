import React, { FC } from 'react';

export const GitHubIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5 0C5.14625 0 0 5.14625 0 11.5C0 16.5887 3.29187 20.8869 7.86312 22.4106C8.43812 22.5112 8.65375 22.1663 8.65375 21.8644C8.65375 21.5912 8.63937 20.6856 8.63937 19.7225C5.75 20.2544 5.0025 19.0181 4.7725 18.3713C4.64313 18.0406 4.0825 17.02 3.59375 16.7469C3.19125 16.5312 2.61625 15.9994 3.57937 15.985C4.485 15.9706 5.13188 16.8188 5.3475 17.1637C6.3825 18.9031 8.03562 18.4144 8.69687 18.1125C8.7975 17.365 9.09937 16.8619 9.43 16.5744C6.87125 16.2869 4.1975 15.295 4.1975 10.8962C4.1975 9.64563 4.64313 8.61063 5.37625 7.80563C5.26125 7.51813 4.85875 6.33938 5.49125 4.75812C5.49125 4.75812 6.45437 4.45625 8.65375 5.93687C9.57375 5.67812 10.5512 5.54875 11.5287 5.54875C12.5062 5.54875 13.4837 5.67812 14.4037 5.93687C16.6031 4.44187 17.5662 4.75812 17.5662 4.75812C18.1987 6.33938 17.7963 7.51813 17.6812 7.80563C18.4144 8.61063 18.86 9.63125 18.86 10.8962C18.86 15.3094 16.1719 16.2869 13.6131 16.5744C14.03 16.9338 14.3894 17.6237 14.3894 18.7019C14.3894 20.24 14.375 21.4762 14.375 21.8644C14.375 22.1663 14.5906 22.5256 15.1656 22.4106C17.4485 21.6399 19.4323 20.1726 20.8377 18.2154C22.2431 16.2582 22.9993 13.9095 23 11.5C23 5.14625 17.8538 0 11.5 0Z'
        fill={color}
      />
    </svg>
  );
};
