import React, { FC } from 'react';

export const ClearDbIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.1 11.5C18.63 11.5 20.7 9.43 20.7 6.9C20.7 4.37 18.63 2.3 16.1 2.3C13.57 2.3 11.5 4.37 11.5 6.9C11.5 9.43 13.57 11.5 16.1 11.5ZM19.55 5.75V8.05H12.65V5.75H19.55ZM10.35 6.9C10.35 5.06 11.27 3.45 12.65 2.3H11.5C7.01501 2.3 3.45001 3.335 3.45001 4.6C3.45001 5.75 6.44001 6.67 10.35 6.9ZM14.72 12.42C13.685 12.535 12.535 12.65 11.5 12.65C7.01501 12.65 3.45001 11.615 3.45001 10.35V13.8C3.45001 15.065 7.01501 16.1 11.5 16.1C15.985 16.1 19.55 15.065 19.55 13.8V11.5C18.515 12.305 17.365 12.65 16.1 12.65C15.64 12.65 15.18 12.535 14.72 12.42ZM11.5 11.5H12.65C11.5 10.695 10.695 9.43 10.465 8.05C6.55501 7.935 3.45001 6.9 3.45001 5.75V9.2C3.45001 10.465 7.01501 11.5 11.5 11.5ZM11.5 17.25C7.01501 17.25 3.45001 16.215 3.45001 14.95V18.4C3.45001 19.665 7.01501 20.7 11.5 20.7C15.985 20.7 19.55 19.665 19.55 18.4V14.95C19.55 16.215 15.985 17.25 11.5 17.25Z'
        fill={color}
      />
    </svg>
  );
};
