import React, { FC } from 'react';

export const AllCategoriesIcon: FC<{ isActive?: boolean }> = ({ isActive = false }) => {
  const color = isActive ? '#3699FF' : '#494B74';
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_6_292)'>
        <path
          d='M7.66669 11.5L2.87502 16.2917L7.66669 21.0833M1.91669 6.70834H19.1667H1.91669ZM15.3334 1.91667L20.125 6.70834L15.3334 11.5V1.91667ZM21.0834 16.2917H3.83335H21.0834Z'
          stroke={color}
          strokeWidth='1.91667'
        />
      </g>
      <defs>
        <clipPath id='clip0_6_292'>
          <rect width='23' height='23' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
